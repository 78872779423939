import React, { useEffect, useState } from "react";
import DataLoader from "../../components/DataLoader";
import { AreaTop } from "../../components";
import config from "../../constants/config";
import { useAuth } from "../../hooks/AuthProvider";

import logo from './img/logo.jpg'
import CommentBox from "./comment";
import { FaPlus } from "react-icons/fa";
import PsychomotorBox from "./psychomo";
import { ja } from "date-fns/locale";
import Swal from "sweetalert2";

function AllClassResults({ classId }) {
    const { user } = useAuth()
    const [isBusy, setIsBusy] = useState(false)
    const [results, setResults] = useState([])
    const [activeId, setActiveId] = useState(null)
    const [openBox, setOpenBox] = useState(false)
    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [state, setState] = useState({
        studentId: null,
        assesment: [
            { name: "Attentiveness", grade: null, unit: null },
            { name: "Conduct", grade: null, unit: null },
            { name: "Neatness", grade: null, unit: null },
            { name: "Politeness", grade: null, unit: null },
            { name: "Punctuality", grade: null, unit: null },
            { name: "Relationship", grade: null, unit: null },
            { name: "Assignment", grade: null, unit: null },
            { name: "Construction", grade: null, unit: null },
            { name: "Fluency", grade: null, unit: null },
            { name: "Hand_Writing", grade: null, unit: null },
            { name: "Sport_and_Games", grade: null, unit: null }
        ]
    })
    const columns = [
        "Subjects",
        "Ass (10)",
        "CA 1 (10)",
        "CA 2 (20)",
        "Total CA (40)",
        "Exams (60)",
        "Total (100)",
        "Class Avg",
        "Class Max",
        "Class Min",
        "Grade",
        "Remark",
    ];

    const RATING = [
        { grade: "A", unit: 5 },
        { grade: "B", unit: 4 },
        { grade: "C", unit: 3 },
        { grade: "D", unit: 2 },
        { grade: "E", unit: 1 }
    ];


    const Motors = [
        "Attentiveness",
        "Conduct",
        "Neatness",
        "Politeness",
        "Punctuality",
        "Relationship",
        "Assignment",
        "Construction",
        "Fluency",
        "Hand_Writing",
        "Sport_and_Games"
    ];

    const effectiveness = [Motors.splice(0, (Motors.length / 2) + 1)][0]
    const pyscho = [Motors.splice(Motors.length / 2 - 2)][0]


    const fetchResults = async () => {
        fetch(`${config.API_URL}/results/all/${classId | user.teacher.currentClass}`, {
            headers: config.Json
        })
            .then(raw => raw.json())
            .then(data => {
                setResults(data.data.assesments)
                setIsBusy(false)
            })
            .catch(err => {
                console.warn(err)
                setIsBusy(false)
            })
    }

    useEffect(() => {
        setIsBusy(true)
        fetchResults()
    }, [])

    const handleUpdate = () => {
        setIsBusy(true)
        fetch(`${config.API_URL}/results/all/${classId | user.teacher.currentClass}`, {
            headers: config.Json
        })
            .then(raw => raw.json())
            .then(data => {
                setResults(data.data.assesments)
                setIsBusy(false)
            })
            .catch(err => {
                console.warn(err)
                setIsBusy(false)
            })
    }

    const openCommentBox = (id) => {
        setActiveId(id)
        setIsOpenAdd(true)
    }

    const getGrade = e => {
        let g = ''
        if (e == 5) g = 'A';
        else if (e == 4) g = 'B';
        else if (e == 3) g = 'C';
        else if (e == 2) g = 'D';
        else g = 'E';
        return g
    }

    const setEffectiveness = (e, j, id) => {
        setState({
            ...state,
            studentId: id,
            assesment: [
                ...state.assesment,
                state.assesment[j].unit = parseInt(e.target.value),
                state.assesment[j].grade = getGrade(parseInt(e.target.value))
            ]
        })
    }

    const setPsycho = (e, j, id) => {
        setState({
            ...state,
            studentId: id,
            assesment: [
                ...state.assesment,
                state.assesment[j + pyscho.length].unit = parseInt(e.target.value),
                state.assesment[j + pyscho.length].grade = getGrade(parseInt(e.target.value))
            ]
        })
    }

    const submitEffe = () => {
        if (state.studentId == null) {
            Swal.fire({
                title: "Warning",
                text: "Can't submit query, no student selected",
                timer: 2500,
                timerProgressBar: true,
                icon: "warning",
                showConfirmButton: false,
                showCancelButton: false
            })
        }
        else {
            setIsBusy(true)
            fetch(`${config.API_URL}/psycho/${state.studentId}`, {
                method: "POST",
                headers: config.Json,
                body: JSON.stringify(state)
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp.code == 1) {
                        setIsBusy(false)
                        Swal.fire({
                            title: "Success",
                            text: "Record saved",
                            timer: 2500,
                            timerProgressBar: true,
                            icon: "success",
                            showConfirmButton: false,
                            showCancelButton: false
                        })
                    }
                    else {
                        setIsBusy(false)
                        Swal.fire({
                            title: "Warning",
                            text: "Record couldn't be saved. something went wrong",
                            timer: 2500,
                            timerProgressBar: true,
                            icon: "warning",
                            showConfirmButton: false,
                            showCancelButton: false
                        })
                    }
                })
                .catch(err => {
                    setIsBusy(false)
                    Swal.fire({
                        title: "Warning",
                        text: "Record couldn't be saved. something went wrong",
                        timer: 2500,
                        timerProgressBar: true,
                        icon: "warning",
                        showConfirmButton: false,
                        showCancelButton: false
                    })
                })
        }
    }



    return (
        <div className="context-area">
            <AreaTop title={"Students Report"} />
            {
                isBusy ? <DataLoader text={'compiling results'} /> : <>
                    <div className="flex p-5 my-2 print:hidden hide">
                        <button className="py-2 px-4 hidden print:hidden text-white rounded bg-blue-600" onClick={() => setIsOpenAdd(true)}>Add General Comment</button>
                    </div>
                    {results.map((result, key) => {
                        return (
                            <><div className="container mx-auto my-5 print:my-0" key={key}>
                                <div className="overflow-auto content-area-table result">
                                    <div className="holder w-full">
                                        <div title="header">
                                            <p className="header-p flex justify-between flex-row">
                                                <span className="sd-abs-pos">
                                                    <img
                                                        src={logo} name="WordPictureWatermark86574548" width="100"
                                                        height="100" border="0" />
                                                </span>
                                                <div className="w-full text-center">
                                                    <h1 className="text-3xl font-extrabold text-black">JOS CHRISTIAN MISSION SCHOOLS</h1>
                                                    <span className="italic mt-2 text-black">No 3 Sambo Atse Road, Katon Rikkos, Jos, Plateau state.</span>
                                                    <h4 className="text-lg font-bold text-black my-2">TERMINAL REPORT</h4>
                                                </div>

                                            </p>
                                        </div>
                                        <p className="western">
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Name:</b></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2">
                                                </font>
                                            </font>
                                            <font color="#808080">
                                                <font face="Times New Roman, serif">
                                                    <font size="2">___________<b style={{ fontSize: "25px !important" }}><u>{result.firstName} {result.lastName}</u></b>_______________ </font>
                                                </font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"> </font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>ADM
                                                    No</b></font>
                                            </font>
                                            <font color="#808080">
                                                <font face="Times New Roman, serif">
                                                    <font size="2">.__________________</font>
                                                </font>
                                            </font>
                                        </p>
                                        <p className="western">
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Session:</b></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2">
                                                </font>
                                            </font>
                                            <font color="#808080">
                                                <font face="Times New Roman, serif">
                                                    <font size="2">_______<b><u>{result.session?.name}</u></b>_____________________ </font>
                                                </font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Term:</b></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2">
                                                </font>
                                            </font>
                                            <font color="#808080">
                                                <font face="Times New Roman, serif">
                                                    <font size="2">_______<b><u>{result.term?.name}</u></b>_________ </font>
                                                </font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"> </font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Class:</b></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2">
                                                </font>
                                            </font>
                                            <font color="#808080">
                                                <font face="Times New Roman, serif">
                                                    <font size="2">______<b><u>{result.section?.name} {result.section?.number}({result.section?.sub})</u></b>_____________</font>
                                                </font>
                                            </font>
                                        </p>
                                        <p className="western">
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Position:</b></font>
                                            </font>
                                            <font color="#808080">
                                                <font face="Times New Roman, serif">
                                                    <font size="2"><b>
                                                    </b></font>
                                                </font>
                                            </font>
                                            <font color="#808080">
                                                <font face="Times New Roman, serif">
                                                    <font size="2">________________<b className="underline text-md">{result.position}</b>___________________
                                                    </font>
                                                </font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"> </font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Out
                                                    Of:</b></font>
                                            </font>
                                            <font color="#808080">
                                                <font face="Times New Roman, serif">
                                                    <font size="2">
                                                        ________<b><u>{result.pupil_count}</u></b>___________</font>
                                                </font>
                                            </font>
                                        </p>
                                        <p className="western n mb-2" align="center"
                                        >
                                            <font face="Times New Roman, serif">
                                                <font size="10"><b className="text-lg">PERFORMANCE
                                                    IN SUBJECTS</b></font>
                                            </font>
                                        </p>
                                        <p className="western">

                                            <table dir="ltr" width="100%" cellpadding="7" cellspacing="0">
                                                <col width="163" />
                                                <col width="28" />
                                                <col width="27" />
                                                <col width="28" />
                                                <col width="48" />
                                                <col width="49" />
                                                <col width="48" />
                                                <col width="44" />
                                                <col width="44" />
                                                <col width="44" />
                                                <col width="49" />
                                                <col width="62" />
                                                <thead>
                                                    <tr>

                                                        {columns.map((head, id) => (
                                                            <td width="28" className="ass p-2" key={id}
                                                            >
                                                                <font face="Times New Roman, serif">
                                                                    <font size="2"><span lang="en-US"><b>{head}</b></span></font>
                                                                </font>

                                                            </td>
                                                        ))}

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {result?.result.map((subject, rowIndex) => (

                                                        <tr key={rowIndex}>
                                                            <td width="163" height="4"
                                                            >
                                                                <p className="western" align="left">
                                                                    <font face="Times New Roman, serif">
                                                                        <font size="2"><span lang="en-US">{subject.subjectName}</span></font>
                                                                    </font>
                                                                </p>
                                                            </td>
                                                            <td width="28">
                                                                <p className="western" align="center">
                                                                    {subject.ASS}
                                                                </p>
                                                            </td>
                                                            <td width="27"
                                                            >
                                                                <p className="western" align="center">
                                                                    {subject.CA1}
                                                                </p>
                                                            </td>
                                                            <td width="28"
                                                            >
                                                                <p className="western" align="center">
                                                                    {subject.CA2}
                                                                </p>
                                                            </td>
                                                            <td width="48"
                                                            >
                                                                <p className="western" align="center">
                                                                    {subject.total_CA}
                                                                </p>
                                                            </td>
                                                            <td width="49"
                                                            >
                                                                <p className="western" align="center">
                                                                    {subject.exams}
                                                                </p>
                                                            </td>
                                                            <td width="48"
                                                            >
                                                                <p className="western" align="center">
                                                                    {subject.total}
                                                                </p>
                                                            </td>
                                                            <td width="44"
                                                            >
                                                                <p className="western" align="center">
                                                                    {parseFloat(subject.average).toPrecision(3)}

                                                                </p>
                                                            </td>
                                                            <td width="44"
                                                            >
                                                                <p className="western" align="center">
                                                                    {subject.classMax}

                                                                </p>
                                                            </td>
                                                            <td width="44"
                                                            >
                                                                <p className="western" align="center">
                                                                    {subject.classMin}

                                                                </p>
                                                            </td>
                                                            <td width="49"
                                                            >
                                                                <p className="western" align="center">
                                                                    {subject.grade}

                                                                </p>
                                                            </td>
                                                            <td width="62"
                                                            >
                                                                <p className="western" align="center">
                                                                    {subject.remark}

                                                                </p>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tr>
                                                    <td colSpan={12} style={{ borderBottom: "1.5pt solid #000000" }}></td>
                                                </tr>

                                            </table>
                                            <br />

                                        </p>

                                        <p className="western" align="center">
                                            <font face="Times New Roman, serif">
                                                <font size="2">
                                                    <b>NO.
                                                        SUBJECT OFFERED: ___{result.result.length}_______ TOTAL SCORE: _____{result.totalScore}_______ AVERAGE
                                                        SCORE: ____{parseFloat(result.AverageScore).toPrecision(4)}______</b>
                                                </font>
                                            </font>
                                        </p>
                                        <p className="western" align="center" style={{ lineHeight: "200%", marginBottom: "0cm" }}>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i><b>GRADE:</b></i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i>
                                                </i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i><b>A</b></i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i>=Excellent,
                                                </i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i><b>B
                                                </b></i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i>=
                                                    Very Good,</i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i><b>
                                                    C</b></i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i>
                                                    = Good,</i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i><b>
                                                    D</b></i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i>
                                                    = Average, </i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i><b>E</b></i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i>
                                                    = Fair,</i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i><b>
                                                    F</b></i></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><i>
                                                    = Fail</i></font>
                                            </font>
                                        </p>
                                        <table width="100%" cellpadding="7" cellspacing="0">
                                            <col width="199" />
                                            <col width="61" />
                                            <col width="77" />
                                            <col width="249" />
                                            <col width="58" />
                                            <col width="64" />
                                            <tr valign="top">
                                                <td width="199" height="5"
                                                >
                                                    <p className="western" align="left">
                                                        <font face="Times New Roman, serif">
                                                            <font size="2">
                                                                <span lang="en-US">EFFECTIVE
                                                                    ASSESSMENT</span></font>
                                                        </font>
                                                    </p>
                                                </td>
                                                <td colSpan="2" width="153"
                                                >
                                                    <p className="western text-center" align="center">
                                                        <font face="Timeste New Roman, serif">
                                                            <font size="2"><span lang="en-US">RATING</span></font>
                                                        </font>
                                                    </p>
                                                </td>
                                                <td width="249"
                                                >
                                                    <p className="western" align="justify">
                                                        <font face="Times New Roman, serif">
                                                            <font size="2"><span lang="en-US">PSYCHOMOTOR
                                                                ASSESSMENT</span></font>
                                                        </font>
                                                    </p>
                                                </td>
                                                <td colSpan="2" width="136"
                                                >
                                                    <p className="western text-center" align="center">
                                                        <font face="Times New Roman, serif">
                                                            <font size="2"><span lang="en-US">RATING</span></font>
                                                        </font>
                                                    </p>
                                                </td>
                                            </tr>
                                            {effectiveness.map((motor, j) => <tr key={j} valign="top">
                                                <td width="199" height="6"
                                                >
                                                    <p className="western" align="justify">
                                                        <font face="Times New Roman, serif">
                                                            <font size="2"><span lang="en-US">{motor}</span></font>
                                                        </font>
                                                    </p>
                                                </td>
                                                <td width="61"
                                                >
                                                    <p className="western items-center justify-center flex" align="justify">

                                                        <select type="text" className="h-1 w-full print:hidden" onChange={(e) => setEffectiveness(e, j, result.id)} >
                                                            {RATING.map((rate, k) => <option style={{ color: "black" }} key={k} value={rate.unit}>{rate.unit}</option>)}
                                                        </select>
                                                        {state.studentId == result.id ? <span className="western" align="justify">
                                                            {
                                                                state.assesment[j].unit != null ?
                                                                    state.assesment[j].unig
                                                                    : result.ratings?.length > 0 ?
                                                                        result.ratings[j].rating
                                                                        : ''
                                                            }
                                                        </span> : <span>{result.ratings?.length > 0 ?
                                                                        result.ratings[j].rating
                                                                        : ''}</span>}
                                                    </p>
                                                </td>
                                                <td width="77"
                                                >
                                                    {state.studentId == result.id ? <p className="western" align="justify">
                                                        {
                                                            state.assesment[j].grade != null ?
                                                                state.assesment[j].grade
                                                                : result.ratings?.length > 0 ?
                                                                    result.ratings[j].grade
                                                                    : ''
                                                        }
                                                    </p> : <p className="western" align="justify">{result.ratings?.length > 0 ?
                                                                    result.ratings[j].grade
                                                                    : ''}</p>}
                                                </td>
                                                <td width="249"
                                                >
                                                    <p className="western" align="justify">
                                                        <font face="Times New Roman, serif">
                                                            <font size="2"><span lang="en-US">{pyscho.length - 1 >= j ? pyscho[j].replaceAll('_', ' ') : ''}</span></font>
                                                        </font>
                                                    </p>
                                                </td>
                                                {j > pyscho.length - 1 ? <>
                                                    <td width="58"
                                                    >
                                                        <p className="western" align="justify">


                                                        </p>
                                                    </td><td width="64"
                                                    >
                                                        <p className="western" align="justify">
                                                            {/* {parseInt(state.assesment[pyscho.length + j].unit)} */}

                                                        </p>
                                                    </td>
                                                </> : <><td width="58"
                                                >
                                                    <p className="western" align="justify">
                                                        <select type="text" className="h-1 w-full print:hidden" onChange={(e) => setPsycho(e, j, result.id)}>
                                                            {RATING.map((rate, k) => <option key={k} value={rate.unit}>{rate.unit}</option>)}
                                                        </select>
                                                        {state.studentId == result.id ? <span className="western" align="justify">
                                                            {
                                                                state.assesment[j + pyscho.length].unit != null ?
                                                                    state.assesment[j + pyscho.length].unig
                                                                    : result.ratings?.length > 0 ?
                                                                        result.ratings[j + pyscho.length].rating
                                                                        : ''
                                                            }
                                                        </span> : <span>{result.ratings?.length > 0 ?
                                                                        result.ratings[j + pyscho.length].rating
                                                                        : ''}</span>}

                                                    </p>
                                                </td><td width="64"
                                                >
                                                        {state.studentId == result.id ? <p className="western" align="justify">
                                                            {
                                                                state.assesment[j + pyscho.length].grade != null ?
                                                                    state.assesment[j + pyscho.length].grade
                                                                    : result.ratings?.length > 0 ?
                                                                        result.ratings[j + pyscho.length].grade
                                                                        : ''
                                                            }
                                                        </p> : <p className="western" align="justify">
                                                            {result.ratings?.length > 0 ?
                                                                        result.ratings[j + pyscho.length].grade
                                                                        : ''}
                                                            </p>}
                                                    </td></>}
                                            </tr>)}

                                        </table>
                                        <p className="western">

                                            <button className="print:hidden px-4 m-1 py-1 rounded" style={{
                                                background: "rgb(37 99 235)",
                                                color: "white"
                                            }}
                                                onClick={() => submitEffe()}
                                            >Save Changes</button>

                                        </p>
                                        <p className="western flex">
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Class
                                                    Teacher’s Remark:</b></font>
                                            </font>
                                            <font className='underline' face="Times New Roman, serif">
                                                <font >
                                                    __________________<b><u>{result.comment}</u></b>_______________________
                                                </font>
                                            </font>

                                            <button
                                                className="w-max h-max mx-2 p-1 print:hidden rounded-full bg-blue-600 text-white"
                                                style={{
                                                    background: "rgb(37 99 235)",
                                                    color: "white"
                                                }}
                                                onClick={() => {
                                                    setActiveId(result.id)
                                                    openCommentBox(result.id)
                                                }}
                                            ><FaPlus size={10} color="white" style={{ color: "white" }} /></button>

                                        </p>
                                        <p className="western">
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Class
                                                    Teacher’s Name:</b></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font className='underline uppercase' size="2">
                                                    ________<b><u>{result?.teacher?.firstName} {result?.teacher?.lastName}</u></b>__________ </font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Signature:</b></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font className='underline' size="2">
                                                    _____________________________</font>
                                            </font>
                                            <font className='underline' face="Times New Roman, serif">
                                                <font size="2">__</font>
                                            </font>
                                        </p>
                                        <p className="western">
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Head
                                                    Teacher’s Remark:</b></font>
                                            </font>
                                            <font className='underline' >
                                                ______________________________________________________________________________
                                            </font>
                                        </p>
                                        <p className="western">
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Head
                                                    Teacher's Name:</b></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font className='underline' size="2">
                                                    _________________<b><u>Wulatonyi Sadiq</u></b>________________ </font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Signature:</b></font>
                                            </font>
                                            <font face="Times New Roman, serif">
                                                <font className='underline' size="2">
                                                    ________________________</font>
                                            </font>
                                        </p>
                                        <p className="western" align="center"><a name="_GoBack"></a>
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>Next
                                                    term Begins on:</b></font>
                                            </font>
                                            <font className='underline' face="Times New Roman, serif">
                                                <font size="2">
                                                    __________________________________</font>
                                            </font>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            </>
                        )
                    })}

                </>
            }
            <CommentBox
                isOpen={isOpenAdd}
                onClose={() => setIsOpenAdd(false)}
                onUpdate={handleUpdate}
                studentID={activeId}
            />
        </div>
    )
}

export default AllClassResults