import { useEffect, useState } from "react"
import config from "../../constants/config";
import { FaPaperPlane } from "react-icons/fa6";
import { Navigate } from "react-router-dom";

const TermModal = ({id, isOpen, onClose, onSet})=>{
    const [isBusy, setIsBusy] = useState(false);
    const [sessions, setSessions] = useState([])
    const [state, setState] = useState({
        term:null,
        session:'',
        studentId: id
    })
    
    useEffect(()=>{
        setIsBusy(true);
        (async()=>{
            fetch(`${config.API_URL}/sessions`, {headers:config.Json})
            .then(json=>json.json())
            .then(res=>{
                if(res.code == 1)
                    {
                        setSessions(res.data)
                    }
            })
        })();
    },[])

    const handler = (e)=>{
      // e.preventDefault();
      sessionStorage.setItem('terms', JSON.stringify(e))
      // Navigate('result/'+id)
    }

    return(
        <div
      className={`fixed inset-0 z-40 flex items-center justify-center ${
        isOpen ? "bg-black bg-opacity-50" : "hidden"
      }`}
    >
      
        <div
          className={`bg-white rounded-lg p-6 w-full max-w-md mx-auto transform transition-transform duration-300 ${
            isOpen ? "scale-100 opacity-100" : "scale-90 opacity-1"
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold" style={{ color: "#383854" }}>
              Select Term
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              &times;
            </button>
          </div>
          <form className="space-y-4" onSubmit={(e)=>{e.preventDefault();onSet(e)}} name="addparent">
          {sessions?.map((session, index)=>{
            return(
                <div key={index}>
                <h2 className="text-lg text-green-950 info-text font-bold m-2">{session.name}</h2>
                <div className="flex items-center border-b border-gray-300 py-5 mb-4">
              <FaPaperPlane className="mr-3 text-gray-400" />
              <select
                type="text"
                placeholder="Select Class"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                
                value={state.term}
                onChange={(e) => {handler({ ...state, term: e.target.value, session:session.id, studentId:id })}}
              ><option >Select term</option>
                {session.terms?.map((term, i)=>{
                    
                    return(
                        <option key={i} value={term.id}>{term.name}</option>
                    )
                })}
              </select>
            </div>
                
                </div>
            )
          })}
            <div className="flex justify-end mt-4">
              <button
                type="button"
                className="px-4 py-2 bg-gray-600 text-white rounded mr-2 close"
                onClick={()=>onClose()}
              >
                Close
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white rounded"
                style={{ backgroundColor: "#2563eb" }}
              >
               Submit
              </button>
            </div>
          </form>
        </div>
      
    </div>
    )
}

export default TermModal