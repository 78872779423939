import React, { useEffect, useState } from "react";
import {
    FaUser,
    FaEnvelope,
    FaPhone,
    FaLock,
    FaUserAlt,
    FaGenderless,
} from "react-icons/fa";
import {
    FaBagShopping,
    FaCalendar,
    FaChalkboardUser,
    FaComment,
    FaLocationPin,
} from "react-icons/fa6";
import Loader from "../../components/Loader";
import config from "../../constants/config";
import Swal from "sweetalert2";
import { useAuth } from "../../hooks/AuthProvider";

const CommentBox = ({ isOpen, onClose, onUpdate, studentID }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [classes, setClasses] = useState([]);
    const { user } = useAuth();
    const [step, setStep] = useState(1);
    const [state, setState] = useState({
        comment: "",
        teacherId: user.teacher.id,
        studentId: studentID,
        classId: user.teacher?.currentClass || "",
    });

    const handler = (e) => {
        e.preventDefault();
        
        if (!document.forms.addcomment.reportValidity()) {
            return false;
        }
        
        setIsLoading(true);

        fetch(`${config.API_URL}/comment/${studentID}`, {
            headers: config.Json,
            method: "post",
            body: JSON.stringify(
                {...state, studentId:studentID}
            ),
        })
            .then((response) => response.json())
            .then((result) => {
                setIsLoading(false);
                if (result.code == 1) {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Record saved",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    onClose();
                    setStep(1);
                    onUpdate(result.data);
                } else {
                    Swal.fire({
                        title: "baaa!",
                        text: "Process failed!",
                        icon: "error",
                    });
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
            });
    };
    useEffect(() => {
       
    }, []);


    return (
        <div
            className={`fixed inset-0 flex z-40 items-center justify-center ${isOpen ? "bg-black bg-opacity-50" : "hidden"
                }`}
        >
            {isLoading ? (
                <Loader />
            ) : (
                <div
                    className={`bg-white  overflow-auto scroll rounded-lg p-6 w-full max-w-md mx-auto transform transition-transform duration-300 ${isOpen ? "scale-100 opacity-100" : "scale-90 opacity-0"
                        }`}
                >
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold" style={{ color: "#383854" }}>
                            Teacher's Remark
                        </h2>
                        <button
                            onClick={onClose}
                            className="text-gray-100 hover:text-gray-600 bg-red-400 py-2 px-3 rounded"
                        >
                            &times;
                        </button>
                    </div>
                    <form className="" onSubmit={handler} name="addcomment">
                <div className="flex items-center border-b border-gray-300 py-2">
                    <FaComment className="mr-3 text-gray-400" />
                    <textarea rows={6}
                        required
                        type="text"
                        placeholder="Teacher's Remark"
                        className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                        name="comment"
                        onChange={(e)=>setState({...state, comment:e.target.value})}
                    ></textarea>
                </div>

                        <div className="flex justify-between mt-4">


                            <button
                                type="submit"
                                className="px-4 py-2 text-white rounded"
                                style={{ backgroundColor: "rgb(37, 99, 235)" }}
                            >
                                Submit
                            </button>

                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default CommentBox;
