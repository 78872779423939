import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import { students as S } from "../../data/student";
import { useEffect, useState } from "react";

import { AreaTop } from "../../components";
import ViewModal from "../../screens/students/viewmodal";
import "../../screens/students/style.scss";
import AddModal from "../../screens/students/addmodal";
import DataLoader from "../../components/DataLoader";

const StudentTable = ({data}) => {
  const theme = useTheme();
  const [students, setStudents] = useState(data);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [id, setId] = useState(null);
  const [student, setStudent] = useState({});
  const [busy, setBusy] = useState(false);
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "gender",
      headerName: "Gender",
      align: "left",
    },
    
   

    {
      headerName: "Action",
      flex: 1,
      field: "action",
      renderCell: ({ row }) => {
        return (
          <Box
            width="auto"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            className="bg-blue-600"
            backgroundColor="rgb(37,99,235)"
            style={{ cursor: "pointer", marginTop: 3, textSize:"100%" }}
            borderRadius="4px"
            onClick={() => {
              setId(row.id);
              setStudent(row);
              setIsOpenView(true);
            }}
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              View
            </Typography>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    setBusy(false);
    
  }, []);

  const ViewStudent = ({ id }) => {
    return (
      <ViewModal
        isOpen={isOpenView}
        id={id}
        onClose={() => setIsOpenView(false)}
        student={student}
      />
    );
  };

  return (
    <div className="context-area">
      
      {busy ? (
        <DataLoader />
      ) : (
        <section className="content-area-table">
          <div className="data-table-info">
            {/* <h4 className="data-table-title">Teachers</h4> */}
            
          </div>
          <div className="data-table-diagra">
            <Box m="10px">
              <Header subtitle="Students" />
              <div className="flex">
                <button
                  className="bg-blue-600 text-white p-2 rounded-lg font-bold"
                  onClick={() => setIsOpenAdd(true)}
                  style={{backgroundColor:"rgb(37 99 235)", color: "rgb(255 255 255)"}}
                >
                  Add Student
                </button>
              </div>
              <Box
                m="60px 0 0 0"
                height="75vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.greenAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                }}
              >
                <DataGrid checkboxSelection rows={students} columns={columns} />
              </Box>
            </Box>
          </div>
        </section>
      )}
      {isOpenView ? <ViewStudent id={id} /> : <></>}
      <AddModal isOpen={isOpenAdd} onClose={() => setIsOpenAdd(false)} />
    </div>
  );
};

export default StudentTable;
