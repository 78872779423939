import config from "../constants/config";

export const students = {
    async data(classID) {
        const response = await fetch(`${config.API_URL}/students${classID? '/'+classID:''}`, {headers:config.Json});
        const res = await response.json();
        return res.data;
    },

    async performanceData(id)
    {
        const response = await fetch(`${config.API_URL}/student/dashboard/${id}`, {headers:config.Json});
        const res = await response.json();
        return res;
    }

}