import React, {useState} from "react";
import { Email, Lock } from "@iconsans/react/linear";
import { Link } from "react-router-dom";
import { FaHandPointRight } from "react-icons/fa6";
import config from '../../constants/config'
import { useAuth } from "../../hooks/AuthProvider";

const Forgotten = () => {
    const [formData, setFormData] = React.useState({ email: "", password: "", confirm:"", role:'' });
  const {  isAuthenticated } = useAuth();
const [state, setState] = useState({
    error: null,
    isAuthenticated: false,
    isLoading: false,
    user: null,
    data:null,
    message:''
  });


  const handleFormSubmit = (e) => {
    e.preventDefault();
    setState({ isLoading: true });
    let form = new FormData(e.target);
    fetch(`${config.API_URL}/password_reset`, {
      method: "post",
      body: JSON.stringify(formData),
     headers:config.Json
    })
      .then((json) => json.json())

      .then((res) => {
        if (res.code == 0) {
          setState({
            isLoading: false,
            error: true,
            message:res.message,
            user: res.data,
            data:res,
            isAuthenticated: true,
          });
          
        } 
        else if(res.code == 1)
          {
            setState({
              isLoading: false,
              error: true,
              user: null,
              isAuthenticated: true,
              message: "A recovery link has been sent to your email"
            });
          }
        else {
          setState({
            isLoading: false,
            error: false,
            user: res.data,
            isAuthenticated: true,
          });
        //   auth.loginAction(res)
          // loginWithRedirect();
        }
      })
      .catch((err) => {
        setState({
          isLoading: false,
          error: true,
          user: res.data,
          isAuthenticated: false,
        });
      });
  };
  return (
    <div className="md:w-1/2 px-8 md:px-16">
      <h2 className="font-bold text-2xl text-[#002D74]">Password Recovery</h2>

      <p className="text-xs mt-4 text-[#002D74]">
        You can contact the school ICT for account recovery or fill the form below
      </p>
      {state.error ? (
        <div className="errorbond mb-0 mt-5 flex">
          <FaHandPointRight size={25} color="red" />
          <div className="text-red-500 ml-4 italic text-md error">
            {state.message}
          </div>
        </div>
      ) : (
        <></>
      )}
      <form
        action="#"
        onSubmit={handleFormSubmit}
        className="flex flex-col gap-4"
      >
        <div className="relative">
          <Email
            fontSize={16}
            color="grey"
            style={{ position: "absolute", bottom: 12, left: 5 }}
          />
          <input
            className="p-2 pl-5 mt-8 text-blue-950 rounded-xl border w-full"
            type={"text"}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            name="email"
            value={formData.email}
            placeholder="Email or Phone Number"
          />
        </div>

        <div className="relative">
          <Lock
            fontSize={16}
            color="grey"
            style={{ position: "absolute", bottom: 12, left: 5 }}
          />
          <select
            className="p-2 pl-5 rounded-xl text-blue-950 border w-full"
            type={"password"}
            onChange={(e) =>
              setFormData({ ...formData, role: e.target.value })
            }
            name="role"
            value={formData.role}
            placeholder="Role"
            required
          >
            <option value="">Who are you</option>
            <option value="student">Pupil</option>
            <option value="teacher">Teacher</option>
            <option value="parent">Parent</option>
          </select>
         
        </div>
        <button className="bg-[#002D74] rounded-xl text-white py-2 hover:scale-105 duration-300">
          Send Recovery link
        </button>
      </form>

      <div className="mt-1 text-xs  py-4 text-[#002D74]">
        <Link to="/login">You can Login here</Link>
      </div>

      {/* <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]">
              <p>Don't have an account?</p>
              <button className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300">
                Register
              </button>
            </div> */}
    </div>
  );
};

export default Forgotten