import { useEffect, useState } from "react";

import { useAuth } from "../../hooks/AuthProvider";

import DataLoader from "../../components/DataLoader";

import "../../components/dashboard/areaCharts/AreaCharts.scss";
import "./styles.scss";
import { AreaTop } from "../../components";
import { Link } from "react-router-dom";
import config from "../../constants/config";
import Performance from "./performance";

const ParentDashboard = () => {
  const { token, user } = useAuth();
  const [canUpadte, setCanUpdate] = useState(true);
  const [children, setChildren] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (canUpadte) {
      setIsBusy(true);
      (async () => {
        user.role == 'parent'?
        fetch(`${config.API_URL}/parent/student/${user.parent.id}`, {headers:config.Json})
          .then((json) => json.json())
          .then((res) => {
            if (res.code == 1) {
              setChildren(res.data);
            }
            setIsBusy(false);
            setCanUpdate(false);
          }):(()=>{
            setIsBusy(false);
            setCanUpdate(false);
          })();
      })();
    }
  }, []);

  return (
    <div className="content-area">
      <AreaTop title={"Dashboard"} />
      {isBusy ? (
        <DataLoader text={"loading..."} />
      ) : (
        <>
          {user.role == "parent" ? (
            <><h4 className="info-text">
                  {children?.length}{" "}
                  {children?.length > 1 ? "Children" : "Child"} found
                </h4>
              <div className="content-area-cards">
                
                  {children?.map((child, index) => {
                    return (
                      <div className="area-card" key={index}>
                        <div className="area-card-info">
                          <div className="info-value capitalize">
                            {child.firstName}
                          </div>
                          <h5 className="info-title capitalize">{`${child.section.name} ${child.section.number} (${child.section.sub})`}</h5>
                          <p className="info-text p-1">{}</p>
                        </div>
                        <div className="area-chart-cards">
                          <Link
                            to={`/dashboard/parent/reports/${child.id}`}
                            className="bg-blue-600 py-2 px-4 rounded "
                          >
                            View Dashboard
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
            </>
          ) : (
            <Performance id={user?.student?.id} />
          )}
          {/* <Performance /> */}
        </>
      )}
    </div>
  );
};

export default ParentDashboard;
