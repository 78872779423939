import Loader from "./Loader"
import '../screens/students/style.scss'

const DataLoader = ({text})=>{
    return(
        <div className={`flex flex-col justify-center align-center items-center`} style={{height:"calc(100vh/1.5)"}}>
      <Loader />
      <span className="text-xl text-yellow-500">{text||"loading data"}</span>
    </div>
    )
}
export default DataLoader