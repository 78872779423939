import { Navigate, Outlet } from "react-router-dom";
import { Sidebar } from "../components";
import { useAuth } from "../hooks/AuthProvider";
import { useEffect } from "react";


const BaseLayout = () => {

  const {user} = useAuth()


  useEffect(()=>{
    if(!user)
      {
        Navigate('/')
      }
  },[])
  
    return (
      <main className="page-wrapper">
        {/* left of page */}
        <Sidebar />
        {/* right side/content of the page */}
        <div className="content-wrapper">
          <Outlet />
        </div>
      </main>
    );
 
};

export default BaseLayout;
