import { Box, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { sessions as S } from "../../data/section";
import React, { useEffect, useState } from "react";

import { AreaTop } from "../../components";
import ViewModal from "./viewmodal";
import "./style.scss";
import AddModal from "./adddal";
import DataLoader from "../../components/DataLoader";

const SessionPage = () => {
  const theme = useTheme();
  const [sessions, setSessions] = useState([]);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [id, setId] = useState(null);
  const [session, setSession] = useState({});
  const [busy, setBusy] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const colors = tokens(theme.palette.mode);
  const apiRef = useGridApiRef();

  const columns = [
    {
      field: "name",
      headerName: "Session",
      cellClassName: "name-column--cell",
      width: 200,
      flex:1
    },
    {
      field: "status",
      headerName: "Status",
      cellClassName: "name-column--cell",
      width: 200,
      flex:1
    },
    {
      headerName: "Action",
      flex: 2,
      field: "action",
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <div className="flex justify-start items-start">
          <Box
              width="max-content"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              className="bg-blue-600 text-white"
              backgroundColor="rgb(37,99,235)"
              style={{ cursor: "pointer", marginTop: 3 }}
              borderRadius="4px"
              onClick={() => {
                // setIndex(sessions.indexOf(row));
                setId(row.id);
                setSession(row);
                setIsOpenView(true);
              }}
            >
              <Typography color={colors.primary} sx={{ ml: "5px" }}>
                Edit
              </Typography>
            </Box>
            <Box
              width="max-content"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              className="bg-orange-600 text-white"
              backgroundColor="rgb(37,99,235)"
              style={{ cursor: "pointer", marginTop: 3 }}
              borderRadius="4px"
              onClick={() => {
                // setIndex(sessions.indexOf(row));
                setId(row.id);
                setSession(row);
                setIsOpenView(true);
              }}
            >
              <Typography color={colors.primary} sx={{ ml: "5px" }}>
                {row.status == 'active'?'End Session':`Set Active`}
              </Typography>
            </Box>
          </div>
        );
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          className="bg-green-600"
          printOptions={{ disableToolbarButton: true }}
        />
        <div className="flex p-5">
          <button
            className="bg-blue-600 text-white p-2 rounded-lg font-bold"
            onClick={() => setIsOpenAdd(true)}
          >
            Add New
          </button>
        </div>
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    if (canUpdate) {
      setBusy(true);
      (async () => {
        const data = await S.data();
        setSessions(data);
        setBusy(false);
        setCanUpdate(false);
      })();
    }
    setTimeout(
      () => apiRef.current.autosizeColumns({ includeOutliers: true }),
      2000
    );
  }, []);

  const handleDeleteRow = (newData) => {
    if (sessions.length === 0) {
      return;
    }
    setSessions(newData);
  };

  const handleUpdate = (newData) => {
    setSessions(newData);
  };

  const ViewClass = ({ id }) => {
    return (
      <ViewModal
        isOpen={isOpenView}
        id={id}
        onClose={() => setIsOpenView(false)}
        session={session}
        onUpdated={handleUpdate}
      />
    );
  };

  return (
    <div className="context-area">
      <AreaTop title="Sessions" />

      {busy ? (
        <DataLoader />
      ) : (
        <section className="content-area-table">
          <div className="data-table-info"></div>
          <div className="data-table-diagram">
            {/* <div className="flex p-5">
              <button
                className="bg-blue-600 p-2 rounded-lg font-bold"
                onClick={() => setIsOpenAdd(true)}
              >
                Add section
              </button>
            </div> */}
            <Box m="5px">
              <Header subtitle="Manage School Sessions" />
              <Box m="40px 0 0 0" minHeight="50vh" sx={{}}>
                <DataGrid
                  // checkboxSelection
                  loading={busy}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  rows={sessions}
                  columns={columns}
                  autosizeOptions={{ includeOutliers: true }}
                  initialState={{
                    ...sessions.initialState,
                    pagination: { paginationModel: { pageSize: 5 } },
                  }}
                  pageSizeOptions={[5, 10, 25, 100]}
                  getRowHeight={() => "auto"}
                  // apiRef={apiRef}
                />
              </Box>
            </Box>
          </div>
        </section>
      )}
      {isOpenView ? <ViewClass id={id} /> : <></>}
      <AddModal
        isOpen={isOpenAdd}
        onClose={() => setIsOpenAdd(false)}
        onUpdated={(data) => handleUpdate(data)}
        onUpdate={handleUpdate}
      />
    </div>
  );
};

export default SessionPage;
