import { useEffect, useState } from "react";
import config from "../../constants/config";
import { FaPaperPlane } from "react-icons/fa6";
import { Navigate } from "react-router-dom";
import Loader from "../../components/Loader";

const TermModal = ({ cln, isOpen, onClose, onSet }) => {
  const [isBusy, setIsBusy] = useState(false);
  const [sections, setSessions] = useState([]);
  const [state, setState] = useState({
    term: cln?.term,
    session:cln?.session,
    section: "",
  });

  useEffect(() => {
    
    setIsBusy(true);
    (async () => {
      fetch(`${config.API_URL}/sections`, { headers: config.Json })
        .then((json) => json.json())
        .then((res) => {
          setSessions(res.data);
          setIsBusy(false);
        });
    })();
  }, []);

 

  return (
    <div
      className={`fixed inset-0 flex z-40 items-center justify-center ${
        isOpen ? "bg-black bg-opacity-50" : "hidden"
      }`}
    >
      <div
        className={`bg-white rounded-lg p-6 min-h-fit max-h-full overflow-auto w-full max-w-md mx-auto transform transition-transform duration-300 ${
          isOpen ? "scale-100 opacity-100" : "scale-90 opacity-1"
        }`}
      >
        {isBusy?<Loader />:<>
          <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold" style={{ color: "#383854" }}>
            Select Class
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            &times;
          </button>
        </div>
        <form
          className="space-y-4"
          onSubmit={(e) => {
            e.preventDefault();
            onSet(state);
          }}
          name="setclass"
        >
          <div>
            <h2 className="text-lg text-green-950 info-text font-bold m-2">
              {"select class"}
            </h2>
            <div className="flex items-center border-b border-gray-300 py-5 mb-4">
              <FaPaperPlane className="mr-3 text-gray-400" />
              <select
                type="text"
                placeholder="Username"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                value={state.section}
                onChange={(e) => {
                  setState({
                    ...state,
                    section: parseFloat(e.target.value),
                  });
                }}
              >
                <option>Select class</option>
                {sections?.map((section, index) => {
                  return (
                    <option
                      key={index}
                      value={section.id}
                    >
                      {section.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="px-4 py-2 bg-gray-600 text-white rounded mr-2 close"
              onClick={() => onClose()}
            >
              Close
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white rounded"
              style={{ backgroundColor: "#2563eb" }}
            >
              Go to Results
            </button>
          </div>
        </form>
        </>}
      </div>
    </div>
  );
};

export default TermModal;
