import React, { useEffect, useState } from "react";
import DataLoader from "../../components/DataLoader";
import { AreaTop } from "../../components";
import config from "../../constants/config";
import { useAuth } from "../../hooks/AuthProvider";

import logo from './img/logo.jpg'
import CommentBox from "./comment";
import { FaPlus } from "react-icons/fa";
import PsychomotorBox from "./psychomo";
import { ja } from "date-fns/locale";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function ResultChecker({ rat }) {
    const { user } = useAuth()
    const [isBusy, setIsBusy] = useState(false)
    const [result, setResult] = useState([])
    const [activeId, setActiveId] = useState(null)
    const [openBox, setOpenBox] = useState(false)
    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [showResult, setShowResult] = useState(false)

    const columns = [
        "Subjects",
        "Ass (10)",
        "CA 1 (10)",
        "CA 2 (20)",
        "Total CA (40)",
        "Exams (60)",
        "Total (100)",
        "Class Avg",
        "Class Max",
        "Class Min",
        "Grade",
        "Remark",
    ];

    const RATING = [
        { grade: "A", unit: 5 },
        { grade: "B", unit: 4 },
        { grade: "C", unit: 3 },
        { grade: "D", unit: 2 },
        { grade: "E", unit: 1 }
    ];


    const Motors = [
        "Attentiveness",
        "Conduct",
        "Neatness",
        "Politeness",
        "Punctuality",
        "Relationship",
        "Assignment",
        "Construction",
        "Fluency",
        "Hand_Writing",
        "Sport_and_Games"
    ];

    const effectiveness = [Motors.splice(0, (Motors.length / 2) + 1)][0]
    const pyscho = [Motors.splice(Motors.length / 2 - 2)][0]


    const fetchResults = async () => {
        setIsBusy(true)
        fetch(`${config.API_URL}/checker/${studentId}`, {
            headers: config.Json
        })
            .then(raw => raw.json())
            .then(data => {
                setResult(data.data)
                setIsBusy(false)
                setShowResult(true)
            })
            .catch(err => {
                console.warn(err)
                setIsBusy(false)
            })
    }

    // useEffect(() => {
    //     fetchResults()
    // }, [])



    const openCommentBox = (id) => {
        setActiveId(id)
        setIsOpenAdd(true)
    }

    const getGrade = e => {
        let g = ''
        if (e == 5) g = 'A';
        else if (e == 4) g = 'B';
        else if (e == 3) g = 'C';
        else if (e == 2) g = 'D';
        else g = 'E';
        return g
    }

    
    const navigate = useNavigate()
    

    const handleCheck = e => {
        e.preventDefault()
        fetchResults()

    }
    const [studentId, setStudentId] = useState('');
    const handleInputChange = (e) => {
        setStudentId(e.target.value);
    };


    if (showResult) {
        return (
            <div className="context-area">
                <AreaTop title={"Students Report"} />
                <div>
                    <button className="print:hidden py-2 px-4
                     bg-green-600 hover:bg-green-800 text-white rounded mx-5"
                     onClick={()=>setShowResult(false)}
                    >Check another</button>
                    <button className="print:hidden py-2 px-4
                     bg-blue-600 hover:bg-blue-800 text-white rounded mx-5"
                     onClick={()=>window.print()}
                     >Save</button>
                </div>
                {
                    isBusy ? <DataLoader text={'compiling result'} /> : <>
                        <div className="flex p-5 my-2 print:hidden hide">
                            <button className="py-2 px-4 hidden print:hidden text-white rounded bg-blue-600" onClick={() => setIsOpenAdd(true)}>Add General Comment</button>
                        </div>
                        <div className="container mx-auto my-5 print:my-0" >
                            <div className="overflow-auto content-area-table result">
                                <div className="holder w-full">
                                    <div title="header">
                                        <p className="header-p flex justify-between flex-row">
                                            <span className="sd-abs-pos">
                                                <img
                                                    src={logo} name="WordPictureWatermark86574548" width="100"
                                                    height="100" border="0" />
                                            </span>
                                            <div className="w-full text-center">
                                                <h1 className="text-3xl font-extrabold text-black">JOS CHRISTIAN MISSION SCHOOLS</h1>
                                                <span className="italic mt-2 text-black">No 3 Sambo Atse Road, Katon Rikkos, Jos, Plateau state.</span>
                                                <h4 className="text-lg font-bold text-black my-2">TERMINAL REPORT</h4>
                                            </div>

                                        </p>
                                    </div>
                                    <p className="western">
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Name:</b></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2">
                                            </font>
                                        </font>
                                        <font color="#808080">
                                            <font face="Times New Roman, serif">
                                                <font size="2">___________<b style={{ fontSize: "25px !important" }}><u>{result.firstName} {result.lastName}</u></b>_______________ </font>
                                            </font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"> </font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>ADM
                                                No</b></font>
                                        </font>
                                        <font color="#808080">
                                            <font face="Times New Roman, serif">
                                                <font size="2">.__________________</font>
                                            </font>
                                        </font>
                                    </p>
                                    <p className="western">
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Session:</b></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2">
                                            </font>
                                        </font>
                                        <font color="#808080">
                                            <font face="Times New Roman, serif">
                                                <font size="2">_______<b><u>{result.session?.name}</u></b>_____________________ </font>
                                            </font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Term:</b></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2">
                                            </font>
                                        </font>
                                        <font color="#808080">
                                            <font face="Times New Roman, serif">
                                                <font size="2">_______<b><u>{result.term?.name}</u></b>_________ </font>
                                            </font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"> </font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Class:</b></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2">
                                            </font>
                                        </font>
                                        <font color="#808080">
                                            <font face="Times New Roman, serif">
                                                <font size="2">______<b><u>{result.section?.name} {result.section?.number}({result.section?.sub})</u></b>_____________</font>
                                            </font>
                                        </font>
                                    </p>
                                    <p className="western">
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Position:</b></font>
                                        </font>
                                        <font color="#808080">
                                            <font face="Times New Roman, serif">
                                                <font size="2"><b>
                                                </b></font>
                                            </font>
                                        </font>
                                        <font color="#808080">
                                            <font face="Times New Roman, serif">
                                                <font size="2">________________<b className="underline text-md">{result.position}</b>___________________
                                                </font>
                                            </font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"> </font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Out
                                                Of:</b></font>
                                        </font>
                                        <font color="#808080">
                                            <font face="Times New Roman, serif">
                                                <font size="2">
                                                    ________<b><u>{result.pupil_count}</u></b>___________</font>
                                            </font>
                                        </font>
                                    </p>
                                    <p className="western n mb-2" align="center"
                                    >
                                        <font face="Times New Roman, serif">
                                            <font size="10"><b className="text-lg">PERFORMANCE
                                                IN SUBJECTS</b></font>
                                        </font>
                                    </p>
                                    <p className="western">

                                        <table dir="ltr" width="100%" cellpadding="7" cellspacing="0">
                                            <col width="163" />
                                            <col width="28" />
                                            <col width="27" />
                                            <col width="28" />
                                            <col width="48" />
                                            <col width="49" />
                                            <col width="48" />
                                            <col width="44" />
                                            <col width="44" />
                                            <col width="44" />
                                            <col width="49" />
                                            <col width="62" />
                                            <thead>
                                                <tr>

                                                    {columns.map((head, id) => (
                                                        <td width="28" className="ass p-2" key={id}
                                                        >
                                                            <font face="Times New Roman, serif">
                                                                <font size="2"><span lang="en-US"><b>{head}</b></span></font>
                                                            </font>

                                                        </td>
                                                    ))}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {result?.result?.map((subject, rowIndex) => (

                                                    <tr key={rowIndex}>
                                                        <td width="163" height="4"
                                                        >
                                                            <p className="western" align="left">
                                                                <font face="Times New Roman, serif">
                                                                    <font size="2"><span lang="en-US">{subject.subjectName}</span></font>
                                                                </font>
                                                            </p>
                                                        </td>
                                                        <td width="28">
                                                            <p className="western" align="center">
                                                                {subject.ASS}
                                                            </p>
                                                        </td>
                                                        <td width="27"
                                                        >
                                                            <p className="western" align="center">
                                                                {subject.CA1}
                                                            </p>
                                                        </td>
                                                        <td width="28"
                                                        >
                                                            <p className="western" align="center">
                                                                {subject.CA2}
                                                            </p>
                                                        </td>
                                                        <td width="48"
                                                        >
                                                            <p className="western" align="center">
                                                                {subject.total_CA}
                                                            </p>
                                                        </td>
                                                        <td width="49"
                                                        >
                                                            <p className="western" align="center">
                                                                {subject.exams}
                                                            </p>
                                                        </td>
                                                        <td width="48"
                                                        >
                                                            <p className="western" align="center">
                                                                {subject.total}
                                                            </p>
                                                        </td>
                                                        <td width="44"
                                                        >
                                                            <p className="western" align="center">
                                                                {parseFloat(subject.average).toPrecision(3)}

                                                            </p>
                                                        </td>
                                                        <td width="44"
                                                        >
                                                            <p className="western" align="center">
                                                                {subject.classMax}

                                                            </p>
                                                        </td>
                                                        <td width="44"
                                                        >
                                                            <p className="western" align="center">
                                                                {subject.classMin}

                                                            </p>
                                                        </td>
                                                        <td width="49"
                                                        >
                                                            <p className="western" align="center">
                                                                {subject.grade}

                                                            </p>
                                                        </td>
                                                        <td width="62"
                                                        >
                                                            <p className="western" align="center">
                                                                {subject.remark}

                                                            </p>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tr>
                                                <td colSpan={12} style={{ borderBottom: "1.5pt solid #000000" }}></td>
                                            </tr>

                                        </table>
                                        <br />

                                    </p>

                                    <p className="western" align="center">
                                        <font face="Times New Roman, serif">
                                            <font size="2">
                                                <b>NO.
                                                    SUBJECT OFFERED: ___{result?.result?.length}_______ TOTAL SCORE: _____{result.totalScore}_______ AVERAGE
                                                    SCORE: ____{parseFloat(result.AverageScore || 0).toPrecision(4)}______</b>
                                            </font>
                                        </font>
                                    </p>
                                    <p className="western" align="center" style={{ lineHeight: "200%", marginBottom: "0cm" }}>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i><b>GRADE:</b></i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i>
                                            </i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i><b>A</b></i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i>=Excellent,
                                            </i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i><b>B
                                            </b></i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i>=
                                                Very Good,</i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i><b>
                                                C</b></i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i>
                                                = Good,</i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i><b>
                                                D</b></i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i>
                                                = Average, </i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i><b>E</b></i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i>
                                                = Fair,</i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i><b>
                                                F</b></i></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><i>
                                                = Fail</i></font>
                                        </font>
                                    </p>
                                    <table width="100%" cellpadding="7" cellspacing="0">
                                        <col width="199" />
                                        <col width="61" />
                                        <col width="77" />
                                        <col width="249" />
                                        <col width="58" />
                                        <col width="64" />
                                        <tr valign="top">
                                            <td width="199" height="5"
                                            >
                                                <p className="western" align="left">
                                                    <font face="Times New Roman, serif">
                                                        <font size="2">
                                                            <span lang="en-US">EFFECTIVE
                                                                ASSESSMENT</span></font>
                                                    </font>
                                                </p>
                                            </td>
                                            <td colSpan="2" width="153"
                                            >
                                                <p className="western text-center" align="center">
                                                    <font face="Timeste New Roman, serif">
                                                        <font size="2"><span lang="en-US">RATING</span></font>
                                                    </font>
                                                </p>
                                            </td>
                                            <td width="249"
                                            >
                                                <p className="western" align="justify">
                                                    <font face="Times New Roman, serif">
                                                        <font size="2"><span lang="en-US">PSYCHOMOTOR
                                                            ASSESSMENT</span></font>
                                                    </font>
                                                </p>
                                            </td>
                                            <td colSpan="2" width="136"
                                            >
                                                <p className="western text-center" align="center">
                                                    <font face="Times New Roman, serif">
                                                        <font size="2"><span lang="en-US">RATING</span></font>
                                                    </font>
                                                </p>
                                            </td>
                                        </tr>
                                        {effectiveness.map((motor, j) => <tr key={j} valign="top">
                                            <td width="199" height="6"
                                            >
                                                <p className="western" align="justify">
                                                    <font face="Times New Roman, serif">
                                                        <font size="2"><span lang="en-US">{motor}</span></font>
                                                    </font>
                                                </p>
                                            </td>
                                            <td width="61"
                                            >
                                                <p className="western items-center justify-center flex" align="justify">

                                                    <span>{result.ratings?.length > 0 ?
                                                        result.ratings[j].rating
                                                        : ''}</span>
                                                </p>
                                            </td>
                                            <td width="77"
                                            >
                                                <p className="western" align="justify">{result.ratings?.length > 0 ?
                                                    result.ratings[j].grade
                                                    : ''}</p>
                                            </td>
                                            <td width="249"
                                            >
                                                <p className="western" align="justify">
                                                    <font face="Times New Roman, serif">
                                                        <font size="2"><span lang="en-US">{pyscho.length - 1 >= j ? pyscho[j].replaceAll('_', ' ') : ''}</span></font>
                                                    </font>
                                                </p>
                                            </td>
                                            {j > pyscho.length - 1 ? <>
                                                <td width="58"
                                                >
                                                    <p className="western" align="justify">


                                                    </p>
                                                </td><td width="64"
                                                >
                                                    <p className="western" align="justify">
                                                        {/* {parseInt(state.assesment[pyscho.length + j].unit)} */}

                                                    </p>
                                                </td>
                                            </> : <><td width="58"
                                            >
                                                <p className="western" align="justify">
                                                    <span>{result.ratings?.length > 0 ?
                                                        result.ratings[j + pyscho.length].rating
                                                        : ''}</span>

                                                </p>
                                            </td><td width="64"
                                            >
                                                    <p className="western" align="justify">
                                                        {result.ratings?.length > 0 ?
                                                            result.ratings[j + pyscho.length].grade
                                                            : ''}
                                                    </p>
                                                </td></>}
                                        </tr>)}

                                    </table>
                                    <p className="western my-1">

                                        {/* <button className="print:hidden px-4 m-1 py-1 rounded" style={{
                                                    background: "rgb(37 99 235)",
                                                    color: "white"
                                                }}
                                                    onClick={() => submitEffe()}
                                                >Save Changes</button> */}

                                    </p>
                                    <p className="western flex">
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Class
                                                Teacher’s Remark:</b></font>
                                        </font>
                                        <font className='underline' face="Times New Roman, serif">
                                            <font >
                                                __________________<b><u>{result.comment}</u></b>_______________________
                                            </font>
                                        </font>



                                    </p>
                                    <p className="western">
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Class
                                                Teacher’s Name:</b></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font className='underline uppercase' size="2">
                                                ________<b><u>{result?.teacher?.firstName} {result?.teacher?.lastName}</u></b>__________ </font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Signature:</b></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font className='underline' size="2">
                                                _____________________________</font>
                                        </font>
                                        <font className='underline' face="Times New Roman, serif">
                                            <font size="2">__</font>
                                        </font>
                                    </p>
                                    <p className="western">
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Head
                                                Teacher’s Remark:</b></font>
                                        </font>
                                        <font className='underline' >
                                            ______________________________________________________________________________
                                        </font>
                                    </p>
                                    <p className="western">
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Head
                                                Teacher's Name:</b></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font className='underline' size="2">
                                                _________________<b><u>Wulatonyi Sadiq</u></b>________________ </font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Signature:</b></font>
                                        </font>
                                        <font face="Times New Roman, serif">
                                            <font className='underline' size="2">
                                                ________________________</font>
                                        </font>
                                    </p>
                                    <p className="western" align="center"><a name="_GoBack"></a>
                                        <font face="Times New Roman, serif">
                                            <font size="2"><b>Next
                                                term Begins on:</b></font>
                                        </font>
                                        <font className='underline' face="Times New Roman, serif">
                                            <font size="2">
                                                __________________________________</font>
                                        </font>
                                    </p>
                                </div>
                            </div>
                        </div>


                    </>
                }
                {/* <CommentBox
                    isOpen={isOpenAdd}
                    onClose={() => setIsOpenAdd(false)}
                    onUpdate={handleUpdate}
                    studentID={activeId}
                /> */}
            </div>
        )
    } else {
       return  <div className="min-h-screen bg-gray-100 flex items-center justify-center">

            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <div className="flex justify-between items-center">
                    <h1 className="text-2xl font-bold mb-4">Check Your Result</h1>
                <button className="px-4 text-white bg-red-600 py-1 rounded text-lg"
                onClick={()=>window.location.href='/'}
                >&times;</button></div>
                
                <form onSubmit={handleCheck}>
                    <div className="mb-4">
                        <label htmlFor="studentId" className="block text-gray-700 mb-2">Enter Access Token</label>
                        <input
                            type="text"
                            id="studentId"
                            value={studentId}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600"
                        disabled={isBusy}
                    >
                        {isBusy ? 'Checking...' : 'Check Result'}
                    </button>
                </form>
                
            </div>
        </div>
    }
}

export default ResultChecker