import React, { useEffect, useState } from "react";
import { FaUser, FaEnvelope, FaPhone, FaGenderless } from "react-icons/fa";
import {
  FaBook,
  FaCertificate,
  FaChalkboard,
  FaLocationPin,
} from "react-icons/fa6";
import Loader from "../../components/Loader";
import config from "../../constants/config";
import Swal from 'sweetalert2'

const Modal = ({ isOpen, onClose, onUpdate  }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [state, setState] = useState({
    email: "",
    role: "parent",
    fname: "",
    lname: "",
    occupation: "",
    phone: "",
    gender: "",
    address: "",
  });
  const handler = (e) => {
    e.preventDefault();

    setIsLoading(true);

    fetch(`${config.API_URL}/parent`, {
      headers: config.Json,
      body: JSON.stringify(state),
      method: "POST",
    })
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if(result.code == 1){
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Record saved",
            showConfirmButton: false,
            timer: 1500
          });
          onClose()
          onUpdate(result.data);
        }else{
          Swal.fire({
            title: "baaa!",
            text: "Process failed!",
            icon: "error",
            showConfirmButton:false,
            timer:1500
          });
          onClose();
        }
        
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          title: "baaa!",
          text: "Something went wrong, Process failed!",
          icon: "error",
          showConfirmButton:false,
          timer:1500
        });
        onClose();
      });
  };
  useEffect(() => {
    // fetch(`${config.API_URL}/classes`, { headers: config.Json })
    //   .then((j) => j.json())
    //   .then((data) => {
    //     setClasses(data.data);
    //   });
  }, []);
  return (
    <div
      className={`fixed inset-0 flex z-40 items-center justify-center ${
        isOpen ? "bg-black bg-opacity-50" : "hidden"
      }`}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className={`bg-white rounded-lg p-6 overflow-auto max-h-full min-h-fit w-full max-w-md mx-auto transform transition-transform duration-300 ${
            isOpen ? "scale-100 opacity-100" : "scale-90 opacity-0"
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold" style={{ color: "#383854" }}>
              Add parent
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              &times;
            </button>
          </div>
          <form className="space-y-4" onSubmit={handler}>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaUser className="mr-3 text-gray-400" />
              <input
                type="text"
                required
                placeholder="First Name"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="fname"
                value={state.fname}
                onChange={(e) => setState({ ...state, fname: e.target.value })}
              />
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaUser className="mr-3 text-gray-400" />
              <input
                type="text"
                required
                placeholder="Last Name"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="lname"
                value={state.lname}
                onChange={(e) => setState({ ...state, lname: e.target.value })}
              />
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaEnvelope className="mr-3 text-gray-400" />
              <input
                type="email"
                required
                placeholder="Email"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="email"
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
              />
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaPhone className="mr-3 text-gray-400" />
              <input
                type="tel"
                required
                placeholder="Phone"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="phone"
                value={state.phone}
                onChange={(e) => setState({ ...state, phone: e.target.value })}
              />
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaCertificate className="mr-3 text-gray-400" />
              <input
                type="text"
                required
                placeholder="Occupation"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="qualification"
                value={state.occupation}
                onChange={(e) =>
                  setState({ ...state, occupation: e.target.value })
                }
              />
            </div>
            {/* <div className="flex items-center border-b border-gray-300 py-2">
              <FaBook className="mr-3 text-gray-400" />
              <input
                type="text"
                placeholder="Course of Study"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="courseofstudy"
                value={state.courseofstudy}
                onChange={(e) =>
                  setState({ ...state, courseofstudy: e.target.value })
                }
              />
            </div> */}
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaLocationPin className="mr-3 text-gray-400" />
              <input
                type="text"
                required
                placeholder="Address"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="courseofstudy"
                value={state.address}
                onChange={(e) =>
                  setState({ ...state, address: e.target.value })
                }
              />
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaGenderless className="mr-3 text-gray-400" />
              <select
                name="gender"
                required
                placeholder="Gender"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                value={state.gender}
                onChange={(e) => setState({ ...state, gender: e.target.value })}
              >
                <option value="">Gender</option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
              </select>
            </div>
            <div className="flex justify-between mt-4">
              <button
                type="button"
                className="px-4 py-2 bg-gray-600 text-white rounded mr-2"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white rounded"
                style={{ backgroundColor: "#383854" }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Modal;
