import PropTypes from "prop-types";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

const AreaCard = ({ colors, percentFillValue, cardInfo, name }) => {
  const filledValue = (percentFillValue / 100) * 360; // 360 degress for a full circle
  const remainedValue = 360 - filledValue;

  const data = [
    { name: name[0], value: remainedValue },
    { name: name[1], value: filledValue },
  ];

  const renderTooltipContent = (value) => {
    return `${parseFloat((value / 360) * 100).toPrecision(3)} %`;
  };

  return (
    <div className="area-card">
      <div className="area-card-info">
        <h5 className="info-title">{cardInfo.title}</h5>
        <div className="info-value">{cardInfo.value}</div>

        <div className="flex w-full justify-start items-center">
          <div
            className="w-3 h-3 mr-1 rounded-full"
            style={{ backgroundColor: `${colors[0]}` }}
          ></div>{" "}
          <p className="info-text capitalize">{name[0]}</p>
        </div>
        <div className="flex w-full justify-start items-center">
          <div
            className="w-3 h-3 mr-1 rounded-full"
            style={{ backgroundColor: `${colors[1]}` }}
          ></div>{" "}
          <p className="info-text capitalize">{name[1]}</p>
        </div>
        <p className="info-text p-1">{cardInfo.text}</p>
      </div>

      <div className="area-card-chart">
        <PieChart width={100} height={100}>
          <Pie
            data={data}
            cx={50}
            cy={45}
            innerRadius={20}
            fill="#e4e8ef"
            paddingAngle={0}
            dataKey="value"
            startAngle={-270}
            endAngle={150}
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={renderTooltipContent} />
        </PieChart>
      </div>
    </div>
  );
};

export default AreaCard;

AreaCard.propTypes = {
  colors: PropTypes.array.isRequired,
  percentFillValue: PropTypes.number.isRequired,
  cardInfo: PropTypes.object.isRequired,
  name: PropTypes.array.isRequired,
};
