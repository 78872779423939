import React, { useEffect, useState } from "react";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  
  FaGenderless,
} from "react-icons/fa";
import { FaBagShopping, FaCalendar, FaCertificate, FaChalkboard, FaGraduationCap, FaMapLocationDot } from "react-icons/fa6";
import Loader from "../../components/Loader";
import config from "../../constants/config";
import "./style.scss";
import Swal from "sweetalert2";

const ViewModal = ({ parent, id, isOpen, onClose, onUpdated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(parent);
  const [data, setData] = useState({ done: false, user: null });
  const [classes, setClasses] = useState([]);
  const handler = (e) => {
    e.preventDefault();

    setIsLoading(true);

   

    fetch(`${config.API_URL}/parent/${id}`, {
      headers:config.Json,
      method:"post",
      body:JSON.stringify(state)
    })
      .then((response) => response.json())
      .then((result) => {
          setIsLoading(false);
          Swal.fire({
            title: "Updated!",
            text: "Succesfully updated",
            icon: "success"
          });
          onUpdated(result.data)
          onClose(data);
        
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          title: "Failed!",
          text: "Process Failed something went wrong",
          icon: "error"
        });
      });
  };
  const handleDelete = ()=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true)
        fetch(`${config.API_URL}/parent/${id}/del`, {
      headers:config.Json,
      method:'post'
    })
        .then(j=>j.json())
        .then(res=>{
          if(res.code == 1)
            {
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
              });
              onUpdated(res.data)
              setIsLoading(false)
              onClose(res.data)
              
            }else if(res.code == 0){
              Swal.fire({
                title: "Failed!",
                text: res.message || "Sorry something went wrong",
                icon: "info"
              });
              setIsLoading(false)
              onClose()
            }
        }).catch(err=>{
          Swal.fire({
            title: "Error!",
            text: err||"Sorry something went wrong",
            icon: "info"
          });
          setIsLoading(false)
          onClose()
        })
      }
    });
  }
 
  return (
    <div
      className={`fixed inset-0 flex z-40 items-center justify-center ${
        isOpen ? "bg-black bg-opacity-50" : "hidden"
      }`}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className={`bg-white rounded-lg p-6 w-full max-w-md mx-auto transform transition-transform duration-300 ${
            isOpen ? "scale-100 opacity-100" : "scale-90 opacity-0"
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold" style={{ color: "#383854" }}>
              Update parent Info
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              &times;
            </button>
          </div>
          <div className="flex">
            <button
            onClick={handleDelete}
              type="submit"
              className="px-4 py-2 text-white rounded bg-red-300"
              style={{
                background:'rgb(252, 165, 165, 1)'
              }}
            >
              Delete parent
            </button>
          </div>
          <form className="space-y-4" onSubmit={handler} name="addparent">
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaUser className="mr-3 text-gray-400" />
              <label htmlFor="name" className=" text-gray-800">
                Fullname:
              </label>
              <input
                type="text"
                placeholder="Full Name"
                className="w-full py-1 px-2 text-gray-500 focus:outline-none"
                name="fname"
                value={state.name}
                onChange={(e) => setState({ ...state, name: e.target.value })}
              />
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaEnvelope className="mr-3 text-gray-400" />
              <input
                type="email"
                placeholder="Email"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="email"
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
              />
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaPhone className="mr-3 text-gray-400" />
              <label htmlFor="name" className=" text-gray-700">
                Contact:
              </label>
              <input
                type="tel"
                placeholder="Phone"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="phone"
                value={state.phone}
                onChange={(e) => setState({ ...state, phone: e.target.value })}
                required={true}
              />
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaCertificate className="mr-3 text-gray-400" />
              <label htmlFor="name" className=" text-gray-700">
                Occupation:
              </label>
              <input
                type="text"
                placeholder="Address"
                className="w-full py-1 px-2 text-gray-600 focus:outline-none"
                name="occupation"
                value={state.occupation || ''}
                onChange={(e) =>
                  setState({ ...state, occupation: e.target.value })
                }
                required={true}
              />
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaMapLocationDot className="mr-3 text-gray-400" />
              <label htmlFor="name" className=" text-gray-700">
                Address:
              </label>
              <input
                type="text"
                placeholder="Address"
                className="w-full py-1 px-2 text-gray-600 focus:outline-none"
                name="address"
                value={state.address || ''}
                onChange={(e) =>
                  setState({ ...state, address: e.target.value })
                }
                required={true}
              />
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaGenderless className="mr-3 text-gray-400" />
              <select
                name="gender"
                placeholder="Password"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                value={state.gender}
                onChange={(e) => setState({ ...state, gender: e.target.value })}
                required={true}
              >
                <option value="">Gender</option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
              </select>
            </div>
            <div className="flex justify-between mt-4">
              <button
                type="button"
                className="px-4 py-2 bg-gray-400 text-white rounded mr-2 close"
                onClick={() => onClose(data)}
                style={{
                  background:'rgba(156, 163, 175, 1)'
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white rounded bg-blue-600"
                style={{
                  background:'rgba(37, 99 ,235, 1)'
                }}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ViewModal;
