import { useEffect, useState } from "react";

import { useAuth } from "../../hooks/AuthProvider";

import DataLoader from "../../components/DataLoader";

import "../../components/dashboard/areaCharts/AreaCharts.scss";
// import "./styles.scss";
import { AreaTop } from "../../components";
import { Link, Navigate } from "react-router-dom";
import config from "../../constants/config";
import TermModal from "./termModal";
import Loader from "../../components/Loader";

const ClassSubjects = ({ subjects, classname }) => {
  const { token, user } = useAuth();
  const [isBusy, setIsBusy] = useState(false);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    setIsBusy(true);
    fetch(`${config.API_URL}/students/assesment`, {
      method: "get",
      headers: config.headers,
    })
      .then((json) => json.json())
      .then((resp) => {
        setStudents(resp.data);
        setIsBusy(false);
      })
      .catch((err) => {
        console.log(err);
        setIsBusy(false);
      });
  }, []);

  const showModal = () => {
    setModalOpen(true);
  };
  // /students/assesment
  const onSet = (data) => {
    setIsBusy(true);
    setModalOpen(false);
    if (data) {
      fetch(`${config.API_URL}/class/subjects`, {
        method: "post",
        headers: config.headers,
        body: JSON.stringify(data),
      })
        .then((json) => json.json())
        .then((resp) => {
          console.log(resp);
          setIsBusy(false);
        })
        .catch((err) => {
          console.log(err);
          setIsBusy(false);
        });
    } else {
      setIsBusy(false);
    }
  };

  const printpage = () => {
    const orin = document.body.innerHTML;
    const prin = document.querySelector(".result").innerHTML;
    document.body.innerHTML = prin;
    window.print();
    document.body.innerHTML = orin;
  };

  if (user.role && user.role == "admin") {
    return (
      <div className="context-area">
        <AreaTop
          title={`Class Assesment Sheet for ${students[0]?.section?.name} ${students[0]?.section?.number} (${students[0]?.section?.sub})`}
        />
        <div className="flex justify-between mb-4">
          <button
            onClick={printpage}
            className="bg--500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            style={{
              backgroundColor: "rgba(29, 78,216,  1",
              color: "#fff",
            }}
          >
            Print Assesment Sheet
          </button>

          <button
            onClick={(e) => window.location.reload()}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Back
          </button>
        </div>
        {isBusy ? (
          <Loader />
        ) : (
          <div className="context-area-cards">
            {subjects == [] ? (
              <h3>No Subjects found</h3>
            ) : (
              <div className="container w-full">
                <div className="overflow-auto w-full result content-area-table">
                  {subjects.length < 1 ? (
                    "NO Assesment for this class"
                  ) : (
                    <table className="min-w-full  border border-gray-700">
                      <thead>
                        <tr className="text-blue-600">
                          <th className="px-4 py-2 border text--600"></th>
                          {subjects?.map((subject, index) => {
                            return (
                              <th
                                key={index}
                                colSpan={3}
                                className="px-2 py-2 border w-fit text--600"
                              >
                                {subject.name}
                              </th>
                            );
                          })}
                        </tr>
                        <tr className="text-blue-600">
                          <th className="px-4 py-2 border text-left text--600">
                            Student Name
                          </th>
                          {subjects?.map((subject, index) => {
                            return (
                              <>
                                <th className="border p-1">CA</th>
                                <th className="border p-1">Exm.</th>
                                <th className="border p-1">TOTAL</th>
                              </>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {students?.map((student, index) => {
                          // console.log(student.assesments);
                          return (
                            <tr key={index} className="border">
                              <td className="px-4 py-2 border text--600 uppercase">
                                {student.firstName} {student.lastName}
                              </td>
                              {student?.assesments?.map((assesment, i) => {
                                let subject = subjects.filter((subject) =>{
                                  return subject.id == assesment.subjectId ? subject : null;
                                
                                }
                                );
                                let isSame = subject.length > 0 ? true : false;
                                if (isSame)
                                  return (
                                    <>
                                      <td className="p-2 text-sm text-center  border text-blue-600 uppercase">
                                        {assesment.total_CA||0}
                                      </td>
                                      <td className="p-2 text-sm text-center  border text-blue-600 uppercase">
                                        {assesment.exams || 0}
                                      </td>
                                      <td
                                        className="px-4 py-2 border font-bolder text-blue-600 uppercase"
                                        style={{ fontWeight: 600 }}
                                      >
                                        {assesment.total || 0}
                                      </td>
                                    </>
                                  );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  } else return <Navigate to={"/dashboard"} />;
};

export default ClassSubjects;
