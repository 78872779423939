import { useEffect, useState } from "react";
import AreaCard from "./AreaCard";
import "./AreaCards.scss";
import config from "../../../constants/config";

const AreaCards = ({ role, data }) => {
  const [pupils, setPupils] = useState({
    male: 0,
    female: 0,
    text: "loading...",
    total: 0,
  });
  

  const [teachers, setTeachers] = useState({
    male: 0,
    female: 0,
    text: "loading...",
    total: 0,
  });

  const [parents, setParents] = useState({
    male: 0,
    female: 0,
    text: "loading...",
    total: 0,
  });

  useEffect(() => {
    if(data && data != []){
      let male = 0;
      let female = 0;
      data.forEach(d=>{
        if(d.gender == 'male')
          {
            male += 1
          }else{
            female += 1;
          }
      });
      setPupils({...pupils, male:male, female:female, total:male+female, text:`${male} Male & ${female} Female`})
    }
    if (!role) {
      fetch(`${config.API_URL}/dashboard/cards`, {
        method: "POST",
        headers: config.headers,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.code == 1) {
            if (res.data.pupils.length > 0) {
              setPupils({
                total:
                  parseFloat(res.data.pupils[0]?.total || 0) +
                  parseFloat(res.data.pupils[1]?.total || 0),
                female: res.data.pupils[0]?.total || 0,
                male: res.data.pupils[1]?.total || 0,
                text: `${res.data.pupils[1]?.total || 0} Male & ${
                  res.data.pupils[0].total || 0
                } Female`,
              });
            } else {
              setPupils({
                total: 0,
                male: 0,
                female: 0,
                text: "no student found",
              });
            }
            if (res.data.teachers.length > 0) {
              setTeachers({
                total:
                  parseFloat(res.data.teachers[0]?.total || 0) +
                  parseFloat(res.data.teachers[1]?.total || 0),
                female: res.data.teachers[0]?.total || 0,
                male: res.data.teachers[1]?.total || 0,
                text: `${res.data.teachers[1]?.total || 0} Male & ${
                  res.data.teachers[0]?.total || 0
                } Female`,
              });
            } else {
              setTeachers({
                total: 0,
                male: 0,
                female: 0,
                text: "no teacher found",
              });
            }

            if (res.data.parents.length > 0) {
              setParents({
                total:
                  parseFloat(res.data.parents[0]?.total || 0) +
                  parseFloat(res.data.parents[1]?.total || 0),
                female: res.data.parents[0]?.total || 0,
                male: res.data.parents[1]?.total || 0,
                text: `${res.data.parents[1]?.total || 0} Male & ${
                  res.data.parents[0]?.total || 0
                } Female`,
              });
            } else {
              setParents({
                total: 0,
                male: 0,
                female: 0,
                text: "no parents found",
              });
            }
          }
        });
    }
    else if(role == 'teacher')
      {

      }
  }, []);

  return (
    <section className="content-area-cards">

      <AreaCard
        colors={["#e4e8ef", "#475be8"]}
        percentFillValue={(100 / pupils.total) * pupils.female}
        cardInfo={{
          title: "Pupils",
          value: pupils.total > 0 ? pupils.total : 0,
          text: pupils.text,
        }}
        name={["male", "female"]}
      />
      {role != "teacher"?<>
      <AreaCard
        colors={["#e4e8ef", "#4ce13f"]}
        percentFillValue={(100 / teachers.total) * teachers.female}
        cardInfo={{
          title: "Teachers",
          value: teachers.total > 0 ? teachers.total : 0,
          text: teachers.text,
        }}
        name={["male", "female"]}
      />
      <AreaCard
        colors={["#e4e8ef", "#f29a2e"]}
        percentFillValue={(100 / parents.total) * parents.female}
        cardInfo={{
          title: "Parents",
          value: parents.total > 0 ? parents.total : 0,
          text: parents.text,
        }}
        name={["male", "female"]}
      /></>:<></>}
    </section>
  );
};

export default AreaCards;
