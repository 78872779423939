import { useEffect, useState } from "react";

import { useAuth } from "../../hooks/AuthProvider";

import DataLoader from "../../components/DataLoader";
import Performance from "./performance"

import "../../components/dashboard/areaCharts/AreaCharts.scss";
import "./styles.scss";
import { AreaTop } from "../../components";
import { Link, Navigate, useParams } from "react-router-dom";

const ChildReport = () => {
  const {  user } = useAuth();
  const [isBusy, setIsBusy] = useState(false)
  const {id} = useParams();



  return (
    <div className="content-area">
      <AreaTop title={"Dashboard"} />
        <Performance id={id} />
      {/* {id?<Performance studentId={id} />:<>
      <h4 className="info-text text-xl">No Report</h4>
      </>} */}
    </div>
  );
};

export default ChildReport;
