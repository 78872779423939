import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import BaseLayout from "./layout/BaseLayout";
import { Invoices, PageNotFound, Settings } from "./screens";
import Login from "./pages/auth/Login";
import PrivateRoute from "./hooks/PrivateRoutes";
import AuthProvider from "./hooks/AuthProvider";
import {
  DashboardTeacher,
  ParentDashboard,
  ChildReport,
  ResultPage,
  ChildResult,
} from "./site";
import Assesment from "./site/teacher/assesment";
import DataLoader from "./components/DataLoader";

import {
  Dashboard,
  Students,
  Teachers,
  Classes,
  Parents,
  Subjects,
  Spreedsheet
} from './screens'
import LandingPage from "./pages/landing/landing";
import SessionPage from "./screens/sessions";
import AllClassResults from "./screens/results/classResults";
import TokenPage from "./screens/tokens";
import ResultChecker from "./screens/results/checker";
import Landing from "./landing";



const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
            {/* <Route path="/sample" element={<Table />} /> */}
            <Route path={"/"} element={<Landing role={'login'} />} />
            <Route path={"/login"} element={<Login role={'login'} />} />
            <Route path={"/password-reset"} element={<Login role={'forgotten'} />} />
            <Route path={"/password-new"} element={<Login role={'newpassword'} />} />
            <Route path={"/recovery/:jwt"} element={<Login role={'reset'} />} />
            <Route path={"/checker"} exact element={<ResultChecker />} />
            <Route element={<PrivateRoute />}>
            
              <Route element={<BaseLayout />}>
                <Route path="/dashboard" exact element={<Dashboard />} />
                <Route
                  path="/dashboard/teacher"
                  exact
                  element={<DashboardTeacher />}
                />
                <Route
                  path="/dashboard/parent"
                  exact
                  element={<ParentDashboard />}
                />
                <Route
                  path="/dashboard/parent/reports/:id"
                  exact
                  element={<ChildReport />}
                />
                <Route
                  path="/dashboard/student"
                  exact
                  element={<ParentDashboard />}
                />
                {/* <Route path="/statistics" element={<Statistics />} /> */}
                <Route path="/subjects" element={<Subjects />} />
                <Route path="/classes" element={<Classes />} />
                <Route path="/sessions" element={<SessionPage />} />

                <Route path="/students" element={<Students />} />
                <Route path="/teachers" element={<Teachers />} />
                <Route path="/parents" element={<Parents />} />

                <Route
                  path="/assesment/spreedsheet/:subjectId/:subjectName"
                  element={<Spreedsheet />}
                />
                <Route
                  path="/assesment/spreedsheet/:subjectId/:subjectName/:teacherId"
                  element={<Spreedsheet />}
                />

                <Route path="/tokens" exact element={<TokenPage />} />

                <Route path="/assesment" element={<Assesment />} />

                <Route path="/results" element={<ResultPage />} />
                <Route path="/results/all" element={<AllClassResults />} />

                <Route path="/results/result/:id" element={<ChildResult />} />

                <Route path="/settings" element={<Settings />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Route>
          </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default RoutesComponent;
