import React, { useEffect, useState } from "react";
import DataLoader from "../../components/DataLoader";
import { AreaTop } from "../../components";
import config from "../../constants/config";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/AuthProvider";
import './style.scss'


function TokenPage() {

    const [isBusy, setIsBusy] = useState(false)
    const [data, setData] = useState([])
    const { user } = useAuth()

    useEffect(() => {
        setIsBusy(true);

        fetch(`${config.API_URL}/keys`, {
            headers: config.Json
        })
            .then(j => j.json())
            .then(resp => {
                setData(resp)
                setIsBusy(false)
            })

    }, [])

    const printpage = () => {
        window.print()
    }


    return (<div className="content-area ">
        <AreaTop title={"Dashboard"} />
        {isBusy ? (
            <DataLoader text={"loading..."} />
        ) : (
            <><h4 className="info-text print:hidden">
                Result access token for all students
            </h4>
            <div className="page ">

                <div className="flex flex-col w-full ">

                    {data?.map((section, key) => {
                        return (
                            <div className="container mx-auto w-max border-2 rounded my-2 card" key={key}>
                                <div className="overflow-auto content-area-table result">
                                    <h4 className="text-black text-2xl font-bold">{section.name} {section.number}({section.sub})</h4>
                                    <div className="flex flex-wrap justify-evenly">
                                        {section.students?.map((student, id) => <div key={id} className="card m-1 p-4 w-max shadow rounded flex justify-start flex-col border">
                                            <h4 className="p-1 font-bold text-lg text-center space-x-3">Jos Christian Mission School
                                                <br /> <span className="text-md"> Result Access Token</span></h4>
                                            <div className="flex-col">
                                                Name:<div className="font-semibold text-md">{student.firstName} {student.lastName}</div>
                                                Class: <div className="font-semibold text-md">{section.name} {section.number}({section.sub})</div>
                                                Token:<div className="font-bold text-xl text-black">{student.token[0].token}</div>
                                            </div>

                                        </div>

                                        )}


                                    </div>

                                </div>
                            </div>
                        );
                    })}
                    </div>
                </div></>

        )}
    </div>)

    // return (
    //     <div className="context-area">
    //         <AreaTop title={"Result Access Token"} />

    //         <div className="flex flex-button justify-between items-center w-full print:hidden">
    //             <button className="p-2 px-6 bg-green-500 rounded m-2 print:hidden " onClick={close}>Return</button>
    //             <button className="p-2 px-6 bg-blue-500 rounded m-2 print:hidden" onClick={printpage}>Print</button>

    //         </div>

    //         {isBusy ? (
    //             <DataLoader text={"generating Result Access Tokens..."} />
    //         ) :

    //             <TokenElement />

    //         }

    //     </div>
    // );

}


export default TokenPage