


const token = localStorage.getItem('jcms.com.ng') || ''

const header = new Headers();


// header.append("_token", token);
header.append("Cookie", "PHPSESSID=ju3msk7v5v1jbn1m1vmrs96ui9");
header.append("Content-Type", "application/x-www-form-urlencoded");
header.append('token', token);

const Json = {
    "Content-Type": "application/json",
    "token": token
  }

const prod = "https://api.jcms.com.ng"
const local = "http://localhost:8000"

export default {
    API_URL: prod,
    headers: header,
    token:token,
    Json:Json
}

// CH6N0#vmag7*K1