import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import { ThemeProvider } from "./context/ThemeContext.jsx";
import { SidebarProvider } from "./context/SidebarContext.jsx";
import { PrimeReactProvider } from "primereact/api";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Portal from "./portal.jsx";
import AuthProvider from "./hooks/AuthProvider.jsx";

const nav = window.location.pathname
// alert(nav)
ReactDOM.createRoot(document.getElementById("root")).render(
  
        <Portal />
      
);
