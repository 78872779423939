
// import '../students/style.scss'
const PageNotFound = () => {
  return (
    <div className="info-text h-full container text-gray-800">
      <h2 className='h-60 font-bold text-3xl justify-center items-center flex'>Page Not Found</h2>
    </div>
  )
}

export default PageNotFound
