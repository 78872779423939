import { useEffect, useState } from "react";
import { students as S } from "../../data/student";
import AreaCard from "../../components/dashboard/areaCards/AreaCard";
import DataLoader from "../../components/DataLoader";

const Performance = ({id}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [topData, setTopData] = useState([]);
  const [canUpdate, setCanUpdate] = useState(true);
  const TABLE_HEADS = ["Progress", "Action"];

  const [assignments, setAssignment] = useState({
    passed: 4,
    failed: 1,
    text: "Passed 4 out of 10 assignments",
    total: 5,
  });

  const [tests, setTest] = useState({
    passed: 18,
    failed: 6,
    text: "Passed 18 Tests, as recorded this Term",
    total: 24,
  });

  const [exams, setExams] = useState({
    passed: 12,
    failed: 2,
    text: "Passed 12 out of 14 exams as recorded this term",
    total: 14,
  });
  useEffect(()=>{
    setIsBusy(true);

      (async () => {
        const data = await S.performanceData(id);
        setTopData(data.performance);
        setAssignment(data.statistics.assignments)
        setTest(data.statistics.tests)
        setExams(data.statistics.exams)
        setIsBusy(false);
      })();
      setCanUpdate(false);
  }, [])
  return (
    <>
    {isBusy?<DataLoader text={'Compiling report ...'} />:
    <>
      <section className="content-area-cards">
        <AreaCard
          colors={["#475be8", "#eb3434"]}
          percentFillValue={(100 / assignments.total) * assignments.failed}
          cardInfo={{
            title: "Assignments",
            value: assignments.total > 0 ? assignments.total : 0,
            text: assignments.text,
          }}
          name={["passed", "failed"]}
        />

        <AreaCard
          colors={["#4ce13f", "#eb3434"]}
          percentFillValue={(100 / tests.total) * tests.failed}
          cardInfo={{
            title: "Tests",
            value: tests.total > 0 ? tests.total : 0,
            text: tests.text,
          }}
          name={["passed", "failed"]}
        />
        <AreaCard
          colors={["#f29a2e", "#eb3434"]}
          percentFillValue={(100 / exams.total) * exams.failed}
          cardInfo={{
            title: "Exams",
            value: exams.total > 0 ? exams.total : 0,
            text: exams.text,
          }}
          name={["passed", "failed"]}
        />
      </section>
      <section className="w-full mt-5 md:w-3/5  ">
        <div className="data-table-info info-text">
          <h4 className="data-table-title">
            Top Performing Subjects According to Average
          </h4>
        </div>
        <div className="data-table-diagram">
          <table>
            <thead>
              <tr className="info-text">
                {TABLE_HEADS?.map((th, index) => (
                  <th className="w-full" key={index}>
                    {th}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {topData?.map((dataItem) => {
                return (
                  <tr key={dataItem.id}>
                    <td>
                      <div className="progress-bar my-1">
                        <div className="progress-bar-list">
                          <div className="progress-bar-item" key={dataItem.id}>
                            <div className="bar-item-info">
                              <p className="bar-item-info-name">
                                {dataItem.name}
                              </p>
                              <p className="bar-item-info-value">
                                {dataItem.average}
                              </p>
                            </div>
                            <div className="bar-item-full">
                              <div
                                className="bar-item-filled"
                                style={{
                                  width: `${dataItem.average}%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="dt-cell-action">
                      {/* <AreaTableAction /> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </>
            }</>
  );
};

export default Performance;