import React from "react";
import { useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { SidebarContext } from "../../context/SidebarContext";
import { useAuth } from "../../hooks/AuthProvider";
import { LIGHT_THEME } from "../../constants/themeConstants";
import LogoBlue from "../../assets/images/logo_blue.svg";
import LogoWhite from "../../assets/images/logo_white.svg";
import {
  MdClass,
  MdOutlineBook,
  MdOutlineClose,
  MdOutlineGridView,
  MdOutlineLogout,
  MdOutlineSettings,
  MdPeopleAlt,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { FaChalkboardUser, FaKey, FaTableCells, FaUsers } from "react-icons/fa6";

const AdminSideBar = () => {
  const { theme } = useContext(ThemeContext);
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);
  const navbarRef = useRef(null);
  const { logOut, user } = useAuth();

  // closing the navbar when clicked outside the sidebar area
  const handleClickOutside = (event) => {
    if (
      navbarRef.current &&
      !navbarRef.current.contains(event.target) &&
      event.target.className !== "sidebar-oepn-btn"
    ) {
      closeSidebar();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <nav
      className={`sidebar ${isSidebarOpen ? "sidebar-show" : ""}`}
      ref={navbarRef}
    >
      <div className="sidebar-top">
        <div className="sidebar-brand">
          <img style={{backgroundSize:"100% 100%"}} src="./logos.jpg" alt="JCMS logo" />
          {/* <span className="sidebar-brand-text">JCMS Portal</span> */}
        </div>
        <button className="sidebar-close-btn" onClick={closeSidebar}>
          <MdOutlineClose size={24} />
        </button>
      </div>
      <div className="sidebar-body">
        <div className="sidebar-menu">
          <ul className="menu-list">
            <li className="menu-item">
              <Link to={`/dashboard`} className="menu-link active">
                <span className="menu-link-icon">
                  <MdOutlineGridView size={18} />
                </span>
                <span className="menu-link-text">Dashboard</span>
              </Link>
            </li>
            {/* <li className="menu-item">
              <Link to="/statistics" className="menu-link">
                <span className="menu-link-icon">
                  <MdOutlineBarChart size={20} />
                </span>
                <span className="menu-link-text">Statistics</span>
              </Link>
            </li> */}
            <hr className="bg-gray-300" />
            <span className="text-gray-400 text-sm italic">Organize</span>

            <li className="menu-item">
              <Link to="/subjects" className="menu-link">
                <span className="menu-link-icon">
                  <MdOutlineBook size={20} />
                </span>
                <span className="menu-link-text">Subjects</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/classes" className="menu-link">
                <span className="menu-link-icon">
                  <MdClass size={18} />
                </span>
                <span className="menu-link-text">Classes</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/students" className="menu-link">
                <span className="menu-link-icon">
                  <FaUsers size={20} />
                </span>
                <span className="menu-link-text">Students</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/teachers" className="menu-link">
                <span className="menu-link-icon">
                  <FaChalkboardUser size={20} />
                </span>
                <span className="menu-link-text">Teachers</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/parents" className="menu-link">
                <span className="menu-link-icon">
                  <FaChalkboardUser size={20} />
                </span>
                <span className="menu-link-text">Parents</span>
              </Link>
            </li>
            <hr />
            <span className="italic text-sm text-gray-400">Assesment</span>
            <li className="menu-item">
              <Link to="/results" className="menu-link">
                <span className="menu-link-icon">
                  <FaTableCells size={20} />
                </span>
                <span className="menu-link-text">Results</span>
              </Link>
            </li>
            <hr />
            <span className="text-sm text-gray-400 italic">Manage</span>

            <li className="menu-item">
              <Link to="/sessions" className="menu-link">
                <span className="menu-link-icon">
                  <MdPeopleAlt size={18} />
                </span>
                <span className="menu-link-text">Session</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/tokens" className="menu-link">
                <span className="menu-link-icon">
                  <FaKey size={18} />
                </span>
                <span className="menu-link-text">Tokens</span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="sidebar-menu sidebar-menu2">
          <ul className="menu-list">
            <li className="menu-item">
              <Link to="/settings" className="menu-link">
                <span className="menu-link-icon">
                  <MdOutlineSettings size={20} />
                </span>
                <span className="menu-link-text">Settings</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link
                to="/logout"
                className="menu-link"
                onClick={(e) => {
                  e.preventDefault();
                  if (confirm("Do you want to logout of the portal.?"))
                    logOut();
                }}
              >
                <span className="menu-link-icon">
                  <MdOutlineLogout size={20} />
                </span>
                <span className="menu-link-text">Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default AdminSideBar;
