import React, { useState } from "react";
import { Email, Lock } from "@iconsans/react/linear";
import { Link } from "react-router-dom";
import { FaHandPointRight } from "react-icons/fa6";
import config from "../../constants/config";
import { useAuth } from "../../hooks/AuthProvider";
import Loader from "../../components/Loader";

const Auth = () => {
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    confirm: "",
  });
  const {loginAction} = useAuth();
  const [state, setState] = useState({
    error: null,
    isAuthenticated: false,
    isLoading: false,
    user: null,
    data: null,
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setState({ isLoading: true });
    let form = new FormData(e.target);
    fetch(`${config.API_URL}/login`, {
      method: "post",
      body: JSON.stringify(formData),
      headers: config.Json,
    })
      .then((json) => json.json())

      .then((res) => {
        if (res.code === 1) {
          setState({
            isLoading: false,
            error: false,
            isAuthenticated: false,
          });
          return loginAction(res)
        }
      })
      .catch((err) => {
        console.log(err)
        setState({
          isLoading: false,
          error: true,
          isAuthenticated: false,
        });
      });
  };
  return (
    <>
      {state.isLoading ? (
        <Loader />
      ) : (
        <div className="md:w-1/2 px-8 md:px-16">
          <h2 className="font-bold text-2xl text-[#002D74]">JCMS Portal</h2>

          <p className="text-xs mt-4 text-[#002D74]">
            Welcome back, kindly sign in to continue with what you were doing
          </p>
          {state.error ? (
            <div className="errorbond mb-0 mt-5 flex">
              <FaHandPointRight size={25} color="red" />
              <div className="text-red-500 ml-4 italic text-md error">
                Invalid email or password
              </div>
            </div>
          ) : (
            <></>
          )}
          <form
            action="#"
            onSubmit={handleFormSubmit}
            className="flex flex-col gap-4"
          >
            <div className="relative">
              <Email
                fontSize={16}
                color="grey"
                style={{ position: "absolute", bottom: 12, left: 5 }}
              />
              <input
                className="p-2 pl-5 mt-8 text-blue-950 rounded-xl border w-full"
                type={"text"}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                name="email"
                value={formData.email}
                placeholder="Email or Phone Number"
              />
            </div>

            <div className="relative">
              <Lock
                fontSize={16}
                color="grey"
                style={{ position: "absolute", bottom: 12, left: 5 }}
              />
              <input
                className="p-2 pl-5 rounded-xl text-blue-950 border w-full"
                type={"password"}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                name="password"
                value={formData.password}
                placeholder="Password"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="gray"
                className="bi bi-eye absolute top-1/2 right-3 -translate-y-1/2"
                viewBox="0 0 16 16"
              >
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
              </svg>
            </div>
            <button className="bg-[#002D74] rounded-xl text-white py-2 hover:scale-105 duration-300">
              Login
            </button>
          </form>

          <div className="mt-1 text-xs  py-4 text-[#002D74]">
            <Link to="/password-reset">Forgotten password?</Link>
          </div>

          {/* <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]">
              <p>Don't have an account?</p>
              <button className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300">
                Register
              </button>
            </div> */}
        </div>
      )}
    </>
  );
};

export default Auth;
