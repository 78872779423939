import { Box, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { students as S } from "../../data/student";
import { useEffect, useState } from "react";

import { AreaTop } from "../../components";
import ViewModal from "./viewmodal";
import "./style.scss";
import AddModal from "./addmodal";
import DataLoader from "../../components/DataLoader";
import { useAuth } from "../../hooks/AuthProvider";
import config from "../../constants/config";

const Students = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [students, setStudents] = useState([]);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [id, setId] = useState(null);
  const [student, setStudent] = useState({});
  const [busy, setBusy] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const colors = tokens(theme.palette.mode);
  const columns = [
    // { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      cellClassName: "name-column--cell",
    },
    {
      field: "gender",
      headerName: "Gender",
      align: "left",
    },
    {
      field: "class",
      headerName: "Class",
      flex: 0,
    },
    {
      field: "phone",
      headerName: "Parent's Contact",
      flex: 0,
      width: 150,
    },

    {
      headerName: "Action",
      flex: 0,
      width: 100,
      field: "action",
      renderCell: ({ row }) => {
        return (
          <Box
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            className="bg-blue-600"
            backgroundColor="rgb(37,99,235)"
            style={{ cursor: "pointer", marginTop: 3 }}
            borderRadius="4px"
            onClick={() => {
              setId(row.id);
              setStudent(row);
              setIsOpenView(true);
            }}
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              View
            </Typography>
          </Box>
        );
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          className="bg-green-600"
          printOptions={{ disableToolbarButton: true }}
        />
        <div className="flex p-5">
          <button
            className="bg-blue-600 text-white p-2 rounded-lg font-bold"
            onClick={() => setIsOpenAdd(true)}
          >
            Add New Student
          </button>
        </div>
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    if (canUpdate) {
      setBusy(true);
      (async () => {
        if (user.role == "admin") {
          const data = await S.data();
          setStudents(data);
          setBusy(false);
          setCanUpdate(false);
        } else if (user.role == "teacher") {
          fetch(`${config.API_URL}/class/students`, {
            headers: config.Json,
            body: JSON.stringify({ classId: user.teacher.currentClass }),
            method: "post",
          })
            .then((json) => json.json())
            .then((res) => {
              setStudents(res.data);
              setBusy(false);
              setCanUpdate(false);
            })
            .catch((err) => {
              console.log(err);
              setBusy(false);
              setCanUpdate(false);
            });
        }
      })();
    }
  }, []);

  const handleDeleteRow = (newData) => {
    if (students.length === 0) {
      return;
    }

    setStudents(newData);
  };

  const handleUpdate = (newData) => {
    setStudents(newData);
  };

  const ViewStudent = ({ id }) => {
    return (
      <ViewModal
        isOpen={isOpenView}
        id={id}
        onClose={() => setIsOpenView(false)}
        student={student}
        onUpdate={handleDeleteRow}
      />
    );
  };

  return (
    <div className="context-area">
      <AreaTop title="Students" />

      {busy ? (
        <DataLoader />
      ) : (
        <section className="content-area-table">
          <div className="data-table-info"></div>
          <div className="data-table-diagram">
            {/* <div className="flex p-5">
              <button
                className="bg-blue-600 p-2 rounded-lg font-bold"
                onClick={() => setIsOpenAdd(true)}
              >
                Add Student
              </button>
            </div> */}
            <Box m="20px">
              <Header subtitle="Manage students" />
              <Box m="4px 0 0 0" width="100%">
                <DataGrid
                  rowHeight={50}
                  checkboxSelection
                  loading={busy}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  rows={students}
                  columns={columns}
                  autosizeOptions={{ includeOutliers: true }}
                  initialState={{
                    ...students.initialState,
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10, 15, 25, 100]}
                />
              </Box>
            </Box>
          </div>
        </section>
      )}
      {isOpenView ? <ViewStudent id={id} /> : <></>}
      <AddModal
        isOpen={isOpenAdd}
        onClose={() => setIsOpenAdd(false)}
        onUpdate={handleUpdate}
      />
    </div>
  );
};

export default Students;
