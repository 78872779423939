import React, { useEffect, useState } from "react";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaGenderless,
  FaSubway,
} from "react-icons/fa";
import {
  Fa42Group,
  FaBook,
  FaCertificate,
  FaChalkboard,
  FaLocationPin,
} from "react-icons/fa6";
import Loader from "../../components/Loader";
import config from "../../constants/config";
import Swal from "sweetalert2";

const Modal = ({ isOpen, onClose, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const year = new Date().getFullYear();
  const [state, setState] = useState({
    start: "",
    end: "",
    term: "",
  });
  const handler = (e) => {
    e.preventDefault();

    setIsLoading(true);

    fetch(`${config.API_URL}/session`, {
      headers: config.Json,
      body: JSON.stringify(state),
      method: "POST",
    })
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Record saved",
          showConfirmButton: false,
          timer: 1500,
        });
        onClose();
        onUpdate(result.data);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          title: "baaa!",
          text: "Something went wrong, Process failed!",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        onClose();
      });
  };
  useEffect(() => {
    // fetch(`${config.API_URL}/classes`, { headers: config.Json })
    //   .then((j) => j.json())
    //   .then((data) => {
    //     setClasses(data.data);
    //   });
  }, []);
  return (
    <div
      className={`fixed inset-0 flex z-40 items-center justify-center ${
        isOpen ? "bg-black bg-opacity-50" : "hidden"
      }`}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className={`bg-white rounded-lg p-6 overflow-auto max-h-full min-h-fit w-full max-w-md mx-auto transform transition-transform duration-300 ${
            isOpen ? "scale-100 opacity-100" : "scale-90 opacity-0"
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold" style={{ color: "#383854" }}>
              Create New Session
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600" style={{
                background:'rgb(252, 165, 165, 1)'
              }}
            >
              &times;
            </button>
          </div>
          <form className="space-y-4" onSubmit={handler} name="addparent">
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaGenderless className="mr-3 text-gray-400" />
              <label htmlFor="datestart">Start</label>
              <input
                required
                type="date"
                placeholder="Start date (YYYY)"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="start"
                pattern=""
                value={state.end}
                onChange={(e) => setState({ ...state, end: e.target.value })}
              />
            </div>

            <div className="flex items-center border-b border-gray-300 py-2">
              <FaGenderless className="mr-3 text-gray-400" />
              <label htmlFor="datestart">Start</label>
              <input
                required
                type="date"
                placeholder="Start (YYYY)"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="end"
                pattern=""
                value={state.start}
                onChange={(e) => setState({ ...state, start: e.target.value })}
                
              />
            </div>

            <div className="flex items-center border-b border-gray-300 py-2">
              <FaGenderless className="mr-3 text-gray-400" />
              <select
                name="term"
                placeholder="Term"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                value={state.term}
                onChange={(e) => setState({ ...state, term: e.target.value })}
                required={true}
              >
                <option value="">Select Active Term</option>
                <option value={"1"}>
                  First Term {state.term == 1 ? `(Active)` : ""}
                </option>
                <option value={"2"}>
                  Second Term {state.term == 2 ? `(Active)` : ""}
                </option>
                <option value={"3"}>
                  Third Term {state.term == 2 ? `(Active)` : ""}
                </option>
              </select>
            </div>
            <div className="flex justify-end mt-4">
              <button
                type="button"
                className="px-4 py-2 bg-gray-400 text-white rounded mr-2 close"
                onClick={() => onClose(data)}
                 style={{
                  background:'rgba(156, 163, 175, 1)'
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white rounded bg-blue-600"
                 style={{
                  background:'rgba(37, 99 ,235, 1)'
                }}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Modal;
