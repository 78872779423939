import config from "../constants/config";

export const sections = {
    async data() {
        const response = await fetch(`${config.API_URL}/sections`, {headers:config.Json});
        const res = await response.json();
        return res.data;
    }

}

export const sessions = {
    async data() {
        const response = await fetch(`${config.API_URL}/sessions`, {headers:config.Json});
        const res = await response.json();
        return res.data;
    }

}