import React, { useEffect, useState } from "react";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaLock,
  FaUserAlt,
  FaGenderless,
} from "react-icons/fa";
import {
  FaBagShopping,
  FaCalendar,
  FaChalkboardUser,
  FaLocationPin,
} from "react-icons/fa6";
import Loader from "../../components/Loader";
import config from "../../constants/config";
import Swal from "sweetalert2";
import { useAuth } from "../../hooks/AuthProvider";

const AddModal = ({ isOpen, onClose, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const { user } = useAuth();
  const [step, setStep] = useState(1);
  const [state, setState] = useState({
    role: "student",
    fname: "",
    lname: "",
    gender: "",
    dob: "",
    parent: {
      phone: "",
      role: "parent",
      fname: "",
      lname: "",
      gender: "",
      occupation: "",
      address: "",
    },
    section: user.teacher?.currentClass || "",
  });
  const year = new Date().getFullYear() - 1;
  const formData = new FormData(document.forms.addstudent);

  const handler = (e) => {
    e.preventDefault();
    if (!document.forms.addstudent.reportValidity()) {
      return false;
    }
    const form = new FormData(document.forms.addstudent);
    setState({
      ...state,
      parent: {
        ...state.parent,
        fname: form.get("pfname") || "",
        lname: form.get("plname") || "",
        gender: form.get("pgender") || "",
        occupation: form.get("poccupation") || "",
        address: form.get("address") || "",
        phone: form.get("pphone") || "",
      },
    });
    setIsLoading(true);

    fetch(`${config.API_URL}/student`, {
      headers: config.Json,
      method: "post",
      body: JSON.stringify({
        ...state,
        parent: {
          ...state.parent,
          fname: form.get("pfname") || "",
          lname: form.get("plname") || "",
          gender: form.get("pgender") || "",
          occupation: form.get("poccupation") || "",
          address: form.get("address") || "",
          phone: form.get("pphone") || "",
        },
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.code == 1) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Record saved",
            showConfirmButton: false,
            timer: 1500,
          });
          onClose();
          setStep(1);
          onUpdate(result.data);
        } else {
          Swal.fire({
            title: "baaa!",
            text: "Process failed!",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    fetch(`${config.API_URL}/classes`, { headers: config.Json })
      .then((j) => j.json())
      .then((data) => {
        setClasses(data.data);
      });
    // fetch(`${config.API_URL}/parents`, { headers: config.Json })
    //   .then((j) => j.json())
    //   .then((data) => {
    //     setParents(data.data);
    //   });

    // setUpdate(false);
  }, []);

  const ParentComponent = () => {
    return (
      <>
        <h4 className="info-text font-bold ">Parent Info</h4>

        <div className="flex items-center border-b border-gray-300 py-2">
          <FaUser className="mr-3 text-gray-400" />
          <input
            required
            type="text"
            placeholder="First Name"
            className="w-full py-1 px-2 text-gray-700 focus:outline-none"
            name="pfname"
            // value={state.parent.fname}
            // onChange={(e) =>
            //   setState({
            //     ...state,
            //     parent: { ...state.parent, fname: e.target.value },
            //   })
            // }
          />
        </div>
        <div className="flex items-center border-b border-gray-300 py-2">
          <FaUser className="mr-3 text-gray-400" />
          <input
            required
            type="text"
            placeholder="Last Name"
            className="w-full py-1 px-2 text-gray-700 focus:outline-none"
            name="plname"
            // value={state.parent.lname}
            // onChange={(e) =>
            //   setState({
            //     ...state,
            //     parent: { ...state.parent, lname: e.target.value },
            //   })
            // }
          />
        </div>
        {/* <div className="flex items-center border-b border-gray-300 py-2">
          <FaEnvelope className="mr-3 text-gray-400" />
          <input required
            type="email"
            placeholder="Email"
            className="w-full py-1 px-2 text-gray-700 focus:outline-none"
            name="pemail"
            value={state.parent.email}
            onChange={(e) => setState({ ...state, email: e.target.value })}
          />
        </div> */}
        <div className="flex items-center border-b border-gray-300 py-2">
          <FaPhone className="mr-3 text-gray-400" />
          <input
            required
            type="tel"
            placeholder="Phone"
            className="w-full py-1 px-2 text-gray-700 focus:outline-none"
            name="pphone"
            // value={state.parent.phone}
            // onChange={(e) =>
            //   setState({
            //     ...state,
            //     parent: { ...state.parent, phone: e.target.value },
            //   })
            // }
          />
        </div>
        <div className="flex items-center border-b border-gray-300 py-2">
          <FaBagShopping className="mr-3 text-gray-400" />
          <input
            required
            type="text"
            placeholder="Occupation"
            className="w-full py-1 px-2 text-gray-700 focus:outline-none"
            name="poccupation"
            // value={state.parent.occupation || ""}
            // onChange={(e) =>
            //   setState({
            //     ...state,
            //     parent: { ...state.parent, occupation: e.target.value },
            //   })
            // }
          />
        </div>
        <div className="flex items-center border-b border-gray-300 py-2">
          <FaGenderless className="mr-3 text-gray-400" />
          <select
            name="pgender"
            placeholder="gender"
            className="w-full py-1 px-2 text-gray-700 focus:outline-none"
            // value={state.parent.gender}
            // onChange={(e) =>
            //   setState({
            //     ...state,
            //     parent: { ...state.parent, gender: e.target.value },
            //   })
            // }
          >
            <option value="">Gender</option>
            <option value={"male"}>Male</option>
            <option value={"female"}>Female</option>
          </select>
        </div>
        <div className="flex items-center border-b border-gray-300 py-2">
          <FaLocationPin className="mr-3 text-gray-400" />
          <input
            required
            type="text"
            placeholder="Address "
            className="w-full py-1 px-2 text-gray-700 focus:outline-none"
            name="address"
            // value={state.parent.address}
            // onChange={(e) =>
            //   setState({
            //     ...state,
            //     parent: { ...state.parent, address: e.target.value },
            //   })
            // }
          />
        </div>
      </>
    );
  };

  const StudentComponent = () => {
    return (
      <>
        <h4 className="info-text font-bold">Student Info</h4>
        <div className="flex items-center border-b border-gray-300 py-2">
          <FaUser className="mr-3 text-gray-400" />
          <input
            required
            type="text"
            placeholder="First Name"
            className="w-full py-1 px-2 text-gray-700 focus:outline-none"
            name="fname"
          />
        </div>
        <div className="flex items-center border-b border-gray-300 py-2">
          <FaUser className="mr-3 text-gray-400" />
          <input
            required
            type="text"
            placeholder="Last Name"
            className="w-full py-1 px-2 text-gray-700 focus:outline-none"
            name="lname"
          />
          <span className="validity"></span>
        </div>

        <div className="flex items-center border-b border-gray-300 py-2">
          <FaCalendar className="mr-3 text-gray-400" />
          <input
            required
            type="date"
            placeholder="Date of birth (YYYY-MM-DD)"
            className="w-full py-1 px-2 text-gray-700 focus:outline-none"
            name="dob"
            pattern=""
            max={`${year}-01-01`}
            min="2000-01-01"
          />
          <span className="validity"></span>
        </div>

        <div className="flex items-center border-b border-gray-300 py-2">
          <FaGenderless className="mr-3 text-gray-400" />
          <select
            name="gender"
            placeholder="Password"
            className="w-full py-1 px-2 text-gray-700 focus:outline-none"
            required
          >
            <option value="">Gender</option>
            <option value={"male"}>Male</option>
            <option value={"female"}>Female</option>
          </select>
          <span className="validity"></span>
        </div>
        <div className="flex items-center border-b border-gray-300 py-2">
          <FaChalkboardUser className="mr-3 text-gray-400" />
          <select
            type="text"
            placeholder="Section"
            className="w-full py-1 px-2 text-gray-700 "
            name="section"
            required
            value={user.teacher?.currentClass||''}
          >
            <option>Assign Class</option>
            {classes?.map((section, index) => {
              return (
                <option key={index} value={section.id}>
                  {section.name} {section.number} ({section.sub}){" "}
                  {section.teachers_count > 0
                    ? `( has ${section.teachers_count} teacher)`
                    : ""}
                </option>
              );
            })}
          </select>
          <span className="validity"></span>
        </div>
      </>
    );
  };
  const handlerNext = (e) => {
    e.preventDefault();
    if (document.forms.addstudent.reportValidity()) {
      const form = new FormData(document.forms.addstudent);
      setState({
        ...state,
        fname: form.get("fname") || "",
        lname: form.get("lname") || "",
        gender: form.get("gender") || "",
        section: form.get("section") || "",
        dob: form.get("dob"),
      });
      setStep(step + 1);
    }
    // setStep(step + 1);
    return false;
  };
  return (
    <div
      className={`fixed inset-0 flex z-40 items-center justify-center ${
        isOpen ? "bg-black bg-opacity-50" : "hidden"
      }`}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className={`bg-white  overflow-auto scroll rounded-lg p-6 w-full max-w-md mx-auto transform transition-transform duration-300 ${
            isOpen ? "scale-100 opacity-100" : "scale-90 opacity-0"
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold" style={{ color: "#383854" }}>
              {step == 1 ? "Add new Student" : "Parent Info"}
            </h2>
            <span className="info-text">{step}/2</span>
            <button
              onClick={onClose}
              className="text-gray-100 hover:text-gray-600 bg-red-400 py-2 px-3 rounded"
            >
              &times;
            </button>
          </div>
          <form className="space-y-4" onSubmit={handler} name="addstudent">
            {/* <div className="flex items-center border-b border-gray-300 py-2">
              <FaUserAlt className="mr-3 text-gray-400" />
              <input required
                type="text"
                placeholder="Username"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="uname"
                value={state.uname}
                onChange={(e) => setState({ ...state, uname: e.target.value })}
              />
            </div> */}

            {step == 1 ? <StudentComponent /> : <></>}
            {step == 2 ? <ParentComponent /> : <></>}

            <div className="flex justify-between mt-4">
              {step == 1 ? (
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-600 text-white rounded mr-2 close"
                  onClick={() => onClose(data)}
                >
                  Cancel
                </button>
              ) : (
                <button
                  type="buttton"
                  onClick={() => setStep(step - 1)}
                  className="px-4 py-2 text-white rounded"
                  style={{ backgroundColor: "#383854" }}
                >
                  Previous
                </button>
              )}
              {step == 1 ? (
                <a
                  type="buttton"
                  onClick={handlerNext}
                  className="px-4 py-2 text-white rounded"
                  style={{ backgroundColor: "#383854" }}
                >
                  Next
                </a>
              ) : (
                <button
                  type="submit"
                  className="px-4 py-2 text-white rounded"
                  style={{ backgroundColor: "rgb(37, 99, 235)" }}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddModal;
