import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

const CustomAlert = ({ message, type, isOpen, onClose }) => {
  const alertStyles = {
    success: 'bg-green-100 border-green-400 text-green-700',
    error: 'bg-red-100 border-red-400 text-red-700',
    warning: 'bg-yellow-100 border-yellow-400 text-yellow-700',
  };

  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={`fixed top-4 right-4 p-4 border rounded ${alertStyles[type]}`} role="alert">
        <span className="block sm:inline">{message}</span>
        <button onClick={onClose} className="absolute top-0 bottom-0 right-0 px-4 py-3">
          <span className="text-2xl">&times;</span>
        </button>
      </div>
    </Transition>
  );
};

export default CustomAlert;
