import { useEffect, useState } from "react";

import { useAuth } from "../../hooks/AuthProvider";

import DataLoader from "../../components/DataLoader";

import "../../components/dashboard/areaCharts/AreaCharts.scss";
// import "./styles.scss";
import { AreaTop } from "../../components";
import { Link, Navigate } from "react-router-dom";
import config from "../../constants/config";
import TermModal from "./termModal";
import ClassSubjects from "./ClasSubjects";

const TermComponent = ({ sessions }) => {
  const { token, user } = useAuth();
  const [canUpadte, setCanUpdate] = useState(true);
  const [children, setChildren] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [showSubjects, setShowSubjects] = useState(false);
  const [stateSubject, setStateSubject] = useState([]);

  useEffect(() => {}, []);

  const showModal = () => {
    setModalOpen(true);
  };

  const onSet = (data) => {
    setIsBusy(true);
    setModalOpen(false);
    if (data) {
      fetch(`${config.API_URL}/class/subjects`, {
        method: "post",
        headers: config.headers,
        body: JSON.stringify(data),
      })
        .then((json) => json.json())
        .then((resp) => {
          setIsBusy(false);
          setStateSubject(resp.data);
          setShowSubjects(true);
        })
        .catch((err) => {
          console.log(err);
          setIsBusy(false);
        });
    } else {
      setIsBusy(false);
    }
  };

  if (user.role && user.role == "admin") {
    return (
      <>
        {showSubjects ? (
          <ClassSubjects subjects={stateSubject} />
        ) : (
          <div className="content-area-cards">
            {sessions?.map((session, index) => {
              return (
                <div key={index}>
                  <h2 className="text-lg text-green-950 info-text font-bold m-2">
                    {session.name} - Session
                  </h2>

                  <>
                    {session.terms.map((term, i) => {
                      return (
                        <div className="area-card" key={i}>
                          <div className="area-card-info">
                            <div className="info-value capitalize">
                              {term.name}
                            </div>
                            <h5 className="info-title capitalize">{` (${
                              session.status == "active" ? "current" : ""
                            })`}</h5>
                          </div>
                          <div className="area-chart-cards">
                            <button
                              onClick={() => {
                                setId({ term: term.id, session: session.id });
                                showModal();
                              }}
                              className="bg-blue-600 py-2 px-4 rounded "
                            >
                              Go to
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </>
                  {modalOpen ? (
                    <TermModal
                      isOpen={modalOpen}
                      cln={id}
                      onClose={() => {
                        setModalOpen(false);
                        setId(null);
                      }}
                      onSet={onSet}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  } else return <Navigate to={"/dashboard"} />;
};

export default TermComponent;
