import { useEffect, useState } from "react";
import { AreaTop } from "../../components";
import { useAuth } from "../../hooks/AuthProvider";
import DataLoader from "../../components/DataLoader";
import config from "../../constants/config";
import { Link } from "react-router-dom";
import AddSubjectModal from "./addSubject";
import ViewSubjectModal from "./view";

const columns = ["Subject", "Teacher", "Action"];

const Subjects = () => {
  const { token, user } = useAuth();
  const [isBusy, setIsBusy] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [openView, setOpenView] = useState(false);

  useEffect(() => {
    setIsBusy(true);
    (async () => {
      fetch(`${config.API_URL}/subjects`, {
        headers: config.Json,
      })
        .then((r) => r.json())
        .then((data) => {
          setSubjects(data.data);
          setIsBusy(false);
        });
    })();
  }, []);

  const handleUpdate = (newData) => {
    setSubjects(newData);
  };

  const OpenViewHandler = ({subject}) => {
    
    return (
      <ViewSubjectModal
        isOpen={openView}
        onClose={() => setOpenView(false)}
        onUpdate={handleUpdate}
        subject={subject}
      />
    );
  };

  return (
    <div className="content-area">
      <AreaTop title={"Subjects"} />

      {isBusy ? (
        <DataLoader text={"fetching data..."} />
      ) : (
        <>
          <div className="container mx-auto">
            <div className="overflow-auto content-area-table">
              <div className="flex p-5">
                <button
                  className="bg-blue-600 text-white p-2 rounded-lg font-bold"
                  onClick={() => setIsOpenAdd(true)}
                  style={{
                    backgroundColor: "rgb(37 99 235)",
                    color: "rgb(255 255 255)",
                  }}
                >
                  Add New
                </button>
              </div>
              <table className="min-w-full  border border-gray-700">
                <thead>
                  <tr className="text-blue-600">
                    {columns.map((value, index) => {
                      return (
                        <th key={index} className="px-4 py-2 border text--600">
                          {value}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {subjects?.map((sec, rowIndex) => (
                    <tr key={rowIndex} className="text-gray-600 font-bold">
                      <td className="px-4 py-2 border text-blue-600 uppercase">
                        <span className="px-4 py-2 w-max text-white rounded-md">
                          {sec.name}
                        </span>
                      </td>

                      <td className="px-4 py-2 border text-blue-600 uppercase">
                        <span className="px-4 py-2 w-max text-white rounded-md">
                          {sec.teacher != null
                            ? `${sec.teacher.firstName} ${sec.teacher.lastName}`
                            : ""}
                        </span>
                      </td>

                      <td className="px-4 py-2 border text--600 uppercase">
                        <button
                          onClick={(e) => {
                            setOpenView(true)
                            setSubject(sec);
                          }}
                          className="px-4 py-2 w-max border text-white rounded-md bg-blue-600"
                          style={{
                            backgroundColor: "rgb(37, 99, 235)",
                            color: "white",
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <OpenViewHandler subject={subject} />
            <AddSubjectModal
              isOpen={isOpenAdd}
              onClose={() => setIsOpenAdd(false)}
              onUpdate={handleUpdate}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Subjects;
