import { Box, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { sections as S } from "../../data/section";
import React, { useEffect, useState } from "react";

import { AreaTop } from "../../components";
import ViewModal from "./viewmodal";
import "./style.scss";
import AddModal from "./adddal";
import DataLoader from "../../components/DataLoader";

const Classes = () => {
  const theme = useTheme();
  const [sections, setSections] = useState([]);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [id, setId] = useState(null);
  const [section, setSection] = useState({});
  const [busy, setBusy] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const colors = tokens(theme.palette.mode);
  const apiRef = useGridApiRef();
  
  const columns = [
    {
      field: "name",
      headerName: "Class",
      cellClassName: "name-column--cell",
      width: 200,
    },
    {
      field: "students_count",
      headerName: "N0. Students",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "teachers",
      headerName: "Teacher(s)",
      flex: 0,
      width:200,
      renderCell: ({ row }) => {
        const teachers = row.teachers;
        return (
          
            teachers?.map((teacher, index) => (
              <><br /><span className="border rounded" key={index}>
                {teacher.firstName} {teacher.lastName}

              </span></>
            ))
          
        );
      },
    },

    {
      headerName: "Action",
      flex: 0,
      field: "action",
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            className="bg-blue-600 text-white"
            backgroundColor="rgb(37,99,235)"
            style={{ cursor: "pointer", marginTop: 3 }}
            borderRadius="4px"
            onClick={() => {
              setIndex(sections.indexOf(row));
              setId(row.id);
              setSection(row);
              setIsOpenView(true);
            }}
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              View
            </Typography>
          </Box>
        );
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          className="bg-green-600"
          printOptions={{ disableToolbarButton: true }}
        />
        <div className="flex p-5">
          <button
            className="bg-blue-600 text-white p-2 rounded-lg font-bold"
            onClick={() => setIsOpenAdd(true)}
          >
            Add New
          </button>
        </div>
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    if (canUpdate) {
      setBusy(true);
      (async () => {
        const data = await S.data();
        setSections(data);
        setBusy(false);
        setCanUpdate(false);
      })();
    }
    setTimeout(()=>apiRef.current.autosizeColumns({includeOutliers: true}), 2000)
  }, []);
  

  const handleDeleteRow = (newData) => {
    if (sections.length === 0) {
      return;
    }
    setSections(newData);
  };

  const handleUpdate = (newData) => {
    setSections(newData);
  };

  const ViewClass = ({ id }) => {
    return (
      <ViewModal
        isOpen={isOpenView}
        id={id}
        onClose={() => setIsOpenView(false)}
        section={section}
        onUpdated={handleUpdate}
      />
    );
  };

  return (
    <div className="context-area">
      <AreaTop title="Classes" />

      {busy ? (
        <DataLoader />
      ) : (
        <section className="content-area-table">
          <div className="data-table-info"></div>
          <div className="data-table-diagram">
            {/* <div className="flex p-5">
              <button
                className="bg-blue-600 p-2 rounded-lg font-bold"
                onClick={() => setIsOpenAdd(true)}
              >
                Add section
              </button>
            </div> */}
            <Box m="5px">
              <Header subtitle="Manage Classes" />
              <Box m="40px 0 0 0" minHeight="50vh" sx={{}}>
                <DataGrid
                  // checkboxSelection
                  loading={busy}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  rows={sections}
                  
                  columns={columns}
                  autosizeOptions={{ includeOutliers: true }}
                  initialState={{
                    ...sections.initialState,
                    pagination: { paginationModel: { pageSize: 5 } },
                  }}
                  pageSizeOptions={[5, 10, 25, 100]}
                  getRowHeight={() => "auto"}
                  apiRef={apiRef}
                />
              </Box>
            </Box>
          </div>
        </section>
      )}
      {isOpenView ? <ViewClass id={id} /> : <></>}
      <AddModal
        isOpen={isOpenAdd}
        onClose={() => setIsOpenAdd(false)}
        onUpdated={(data) => handleUpdate(data)}
        onUpdate={handleUpdate}
      />
    </div>
  );
};

export default Classes;
