import React, { useEffect, useState } from "react";

import { useNavigate, Navigate } from "react-router-dom";

import cover from "../../assets/images/cover.avif";

import Loader from "../../components/Loader";

import { useAuth } from "../../hooks/AuthProvider";
import Auth from "./auth";
import Forgotten from "./forgotten";
import Recovery from "./recovery";

function Login({ role }) {
  const auth = useAuth();

  const [state, setState] = useState({
    error: null,
    isAuthenticated: false,
    isLoading: false,
    user: null,
    data: null,
  });
  if (auth.isAuthenticated) {
    return <Navigate to={"/dashboard"} replace />;
  } else {
    return (
      <>
        <section className="bg-white h-dvh flex flex-col">
          <div className=" h-full p-5  flex items-center justify-center">
            <div className="bg-gray-100 flex rounded-3xl shadow-xl max-w-3xl justify-center p-5 items-center">
              {state.isLoading ? (
                <Loader />
              ) : (
                <>
                  {role == 'login' ? <Auth /> : <></>}
                  {role == 'forgotten' ? <Forgotten /> : <></>}
                  {role == 'reset' ? <Recovery /> : <></>}
                </>
              )}
              <div className="md:block hidden w-1/2">
                <img className="rounded-2xl" src={cover} />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Login;
