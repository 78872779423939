import { Box, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { teachers as S } from "../../data/teacher";
import { useEffect, useState } from "react";

import { AreaTop } from "../../components";
import ViewModal from "./viewmodal";
import "./style.scss";
import AddModal from "./addmodal";
import DataLoader from "../../components/DataLoader";

const Teachers = () => {
  const theme = useTheme();
  const [teachers, setTeachers] = useState([]);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [id, setId] = useState(null);
  const [teacher, setTeacher] = useState({});
  const [busy, setBusy] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const colors = tokens(theme.palette.mode);
  const [index, setIndex] = useState(null);
  const apiRef = useGridApiRef();
  // apiRef.current.autosizeColumns({includeOutliers: true})
  const columns = [
    // { field: "id", headerName: "Staff ID" },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 200,
    },
    // {
    //   field: "qualification",
    //   headerName: "Qualification",
    //   headerAlign: "left",
    //   align: "left",
    // },
    {
      field: "gender",
      headerName: "Gender",
      align: "center",
      width: 100,
    },
    {
      field: "class",
      headerName: "Class",
      //   flex: 1,
      align: "center",
      width: 100,
    },
    {
      field: "phone",
      headerName: "Contact",
      flex: 0,
      width: 200,
    },

    {
      headerName: "Action",
      flex: 0,
      field: "action",
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            className="bg-blue-600 text-white"
            backgroundColor="rgb(37,99,235)"
            style={{ cursor: "pointer", marginTop: 3 }}
            borderRadius="4px"
            onClick={() => {
              setIndex(teachers.indexOf(row));
              setId(row.id);
              setTeacher(row);
              setIsOpenView(true)
              // handleDeleteRow(teachers);
            }}
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              View
            </Typography>
          </Box>
        );
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          className="bg-green-600"
          printOptions={{ disableToolbarButton: true }}
        />
        <div className="flex p-5">
          <button
            className="bg-blue-600 text-white p-2 rounded-lg font-bold"
            onClick={() => setIsOpenAdd(true)}
          >
            Add New
          </button>
        </div>
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    if (canUpdate) {
      setBusy(true);
      (async () => {
        const data = await S.data();
        setTeachers(data);
        setBusy(false);
        setCanUpdate(false);
      })();
    }
  }, []);

  const handleDeleteRow = (newData) => {
    if (teachers.length === 0) {
      return;
    }
    setTeachers(newData)
  };

  const handleUpdate = (newData) => {
    setTeachers(newData);
  };

  const ViewTeacher = ({ id }) => {
    return (
      <ViewModal
        isOpen={isOpenView}
        id={id}
        onClose={() => setIsOpenView(false)}
        teacher={teacher}
          onUpdated={handleUpdate}
      />
    );
  };

  return (
    <div className="context-area">
      <AreaTop title="Teachers" />

      {busy ? (
        <DataLoader />
      ) : (
        <section className="content-area-table">
          <div className="data-table-info"></div>
          <div className="data-table-diagram">
            {/* <div className="flex p-5">
              <button
                className="bg-blue-600 p-2 rounded-lg font-bold"
                onClick={() => setIsOpenAdd(true)}
              >
                Add teacher
              </button>
            </div> */}
            <Box m="5px">
              <Header subtitle="Manage Teacher" />
              <Box m="40px 0 0 0" minHeight="50vh" sx={{}}>
                <DataGrid
                  // checkboxSelection
                  loading={busy}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  rows={teachers}
                  columns={columns}
                  autosizeOptions={{ includeOutliers: false }}
                  initialState={{
                    ...teachers.initialState,
                    pagination: { paginationModel: { pageSize: 5 } },
                  }}
                  pageSizeOptions={[5, 10, 25, 100]}
                  getRowHeight={() => "auto"}
                  apiRef={apiRef}
                />
              </Box>
            </Box>
          </div>
        </section>
      )}
      {isOpenView ? (
        <ViewTeacher
          id={id}
          
        />
      ) : (
        <></>
      )}
      <AddModal
        isOpen={isOpenAdd}
        onClose={() => setIsOpenAdd(false)}
        onUpdated={(data) => handleUpdate(data)}
        onUpdate={handleUpdate}
      />
    </div>
  );
};

export default Teachers;
