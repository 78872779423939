import React from "react";
import { useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { SidebarContext } from "../../context/SidebarContext";
import { useAuth } from "../../hooks/AuthProvider";
import { LIGHT_THEME } from "../../constants/themeConstants";
import LogoBlue from "../../assets/images/logo_blue.svg";
import LogoWhite from "../../assets/images/logo_white.svg";
import {
    MdClass,
    MdOutlineBook,
    MdOutlineClose,
    MdOutlineGridView,
    MdOutlineLogout,
    MdOutlineSettings,
    MdPeopleAlt,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { FaChalkboardUser, FaTableCells, FaUsers } from "react-icons/fa6";

const UserSideBar = () => {
    const { theme } = useContext(ThemeContext);
    const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);
    const navbarRef = useRef(null);
    const { logOut, user } = useAuth();

    // closing the navbar when clicked outside the sidebar area
    const handleClickOutside = (event) => {
        if (
            navbarRef.current &&
            !navbarRef.current.contains(event.target) &&
            event.target.className !== "sidebar-oepn-btn"
        ) {
            closeSidebar();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <nav
            className={`sidebar print:hidden ${isSidebarOpen ? "sidebar-show" : ""}`}
            ref={navbarRef}
        >
            <div className="sidebar-top">
                <div className="sidebar-brand">
                    <img src={theme === LIGHT_THEME ? LogoBlue : LogoWhite} alt="" />
                    <span className="sidebar-brand-text">JCMS Portal</span>
                </div>
                <button className="sidebar-close-btn" onClick={closeSidebar}>
                    <MdOutlineClose size={24} />
                </button>
            </div>
            <div className="sidebar-body">
                <div className="sidebar-menu">
                    <ul className="menu-list">
                        <li className="menu-item">
                            <Link
                                to={`/dashboard/`}
                                className="menu-link active"
                            >
                                <span className="menu-link-icon">
                                    <MdOutlineGridView size={18} />
                                </span>
                                <span className="menu-link-text">Dashboard</span>
                            </Link>
                        </li>

                        <hr className="bg-gray-300" />
                        <span className="text-gray-400 text-sm italic">Organize</span>


                        <li className="menu-item">
                            <Link to="/results" className="menu-link">
                                <span className="menu-link-icon">
                                    <FaTableCells size={20} />
                                </span>
                                <span className="menu-link-text">Check Results</span>
                            </Link>
                        </li>

                    </ul>
                </div>

                <div className="sidebar-menu sidebar-menu2">
                    <ul className="menu-list">
                        <li className="menu-item">
                            <Link to="/settings" className="menu-link">
                                <span className="menu-link-icon">
                                    <MdOutlineSettings size={20} />
                                </span>
                                <span className="menu-link-text">Settings</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                to="/logout"
                                className="menu-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (confirm("Do you want to logout of the portal.?"))
                                        logOut();
                                }}
                            >
                                <span className="menu-link-icon">
                                    <MdOutlineLogout size={20} />
                                </span>
                                <span className="menu-link-text">Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )

}


export default UserSideBar