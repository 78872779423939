import { useEffect, useState } from "react";
import {
  AreaCards,
  AreaCharts,
  AreaTable,
  AreaTop,
  StudentResults,
} from "../../components";
import { useAuth } from "../../hooks/AuthProvider";
import { useJwt } from "react-jwt";
import StudentTable from "./studentTable";
import DataLoader from "../../components/DataLoader";
import { students as S } from "../../data/student";

const DashboardTeacher = () => {
  const { token, user } = useAuth();
  const [students, setStudents] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    setIsBusy(true);
    try {
      (async () => {
        const data = await S.data(user?.teacher?.currentClass);
        setStudents(data);
        setIsBusy(false);
      })();
    } catch (error) {
      console.warn(error)
      setIsBusy(false)
    }
    
  }, []);

  return (
    <div className="content-area">
      <AreaTop title={"Dashboard"} />

      {isBusy ? (
        <DataLoader text={"fetching data..."} />
      ) : (
        <>
          <AreaCards data={students} role={user.role} />
          <StudentTable data={students} />
        </>
      )}
    </div>
  );
};

export default DashboardTeacher;
