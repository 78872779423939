import { useParams } from "react-router-dom"
import { Result } from "../../screens"

const ChildResult = ()=>{
    const {id} = useParams()
    return(
        <Result id={id} />
    )
}

export default ChildResult