import React, { useEffect, useState } from "react";

import { AreaTop } from "../../components";
import DataLoader from "../../components/DataLoader";
import config from "../../constants/config";
import "./style.scss";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/AuthProvider";
import logo from './img/logo.jpg'



const columns = [
  "Subjects",
  "Ass (10)",
  "CA 1 (10)",
  "CA 2 (20)",
  "Total CA (40)",
  "Exams (60)",
  "Total (100)",
  "Class Avg",
  "Class Max",
  "Class Min",
  "Grade",
  "Remark",
];

function Result({ id, form, close }) {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(true);
  const [isBusy, setIsBusy] = useState(false);
  const [result, setResult] = useState({});
  const { user } = useAuth()

  useEffect(() => {
    // if(!id){
    //   return Navigate('/dashboard'+user.role)
    // }
    setIsBusy(true);
    (async () => {
      fetch(`${config.API_URL}/result/${id}`, { headers: config.Json, method: 'post', body: form })
        .then((r) => r.json())
        .then((d) => {
          if (d.code == 1) {
            setData(d.data.result);
            setResult(d.data);
            setIsBusy(false);
          } else {
            setData(null)
          }
        })
        .catch((e) => console.log(e));

    })();
    // console.log(data);

  }, []);


  const printpage = () => {
    
    window.print()
  }

  return (
    <div className="context-area">
      <AreaTop title={"Result Sheet"} />
      {(id && data) ? <>
        <div className="flex flex-button justify-between items-center w-full print:hidden">
          <button className="p-2 px-6 bg-green-500 rounded m-2 print:hidden " onClick={close}>Return</button>
          <button className="p-2 px-6 bg-blue-500 rounded m-2 print:hidden" onClick={printpage}>Print</button>

        </div>

        {isBusy ? (
          <DataLoader text={"Compiling Result..."} />
        ) :


          <div className="container mx-auto ">
            <div className="overflow-auto content-area-table result">
              <div className="holder w-full">
                <div title="header">
                  <p className="header-p flex justify-between flex-row">
                    <span className="sd-abs-pos" >
                      <img
                        src={logo} name="WordPictureWatermark86574548" width="100"
                        height="100" border="0" />
                    </span>
                    <div className="w-full text-center">
                      <h1 className="text-3xl font-extrabold text-black">JOS CHRISTIAN MISSION SCHOOLS</h1>
                      <span className="italic mt-2 text-black">No 3 Sambo Atse Road, Katon Rikkos, Jos, Plateau state.</span>
                      <h4 className="text-lg font-bold text-black my-2">TERMINAL REPORT</h4>
                    </div>

                  </p>
                </div>
                <p className="western" >
                  <font face="Times New Roman, serif">
                    <font size="2" ><b>Name:</b></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2">
                    </font>
                  </font>
                  <font color="#808080">
                    <font face="Times New Roman, serif">
                      <font size="2">___________<b style={{fontSize:"25px !important"}}><u>{result.firstName} {result.lastName}</u></b>_______________ </font>
                    </font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2"> </font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2"><b>ADM
                      No</b></font>
                  </font>
                  <font color="#808080">
                    <font face="Times New Roman, serif">
                      <font size="2">.__________________</font>
                    </font>
                  </font>
                </p>
                <p className="western" >
                  <font face="Times New Roman, serif">
                    <font size="2"><b>Session:</b></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2">
                    </font>
                  </font>
                  <font color="#808080">
                    <font face="Times New Roman, serif">
                      <font size="2">_______<b><u>{result.session?.name}</u></b>_____________________ </font>
                    </font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2"><b>Term:</b></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2">
                    </font>
                  </font>
                  <font color="#808080">
                    <font face="Times New Roman, serif">
                      <font size="2">_______<b><u>{result.term?.name}</u></b>_________ </font>
                    </font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2"> </font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2"><b>Class:</b></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2">
                    </font>
                  </font>
                  <font color="#808080">
                    <font face="Times New Roman, serif">
                      <font size="2">______<b><u>{result.section?.name} {result.section?.number}({result.section?.sub})</u></b>_____________</font>
                    </font>
                  </font>
                </p>
                <p className="western" >
                  <font face="Times New Roman, serif">
                    <font size="2"><b>Position:</b></font>
                  </font>
                  <font color="#808080">
                    <font face="Times New Roman, serif">
                      <font size="2"><b>
                      </b></font>
                    </font>
                  </font>
                  <font color="#808080">
                    <font face="Times New Roman, serif">
                      <font size="2">________<b className="underline text-md">{result.position}</b>______
                      </font>
                    </font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2"> </font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2"><b>Out
                      Of:</b></font>
                  </font>
                  <font color="#808080">
                    <font face="Times New Roman, serif">
                      <font size="2">
                        ________<b><u>{result.pupil_count}</u></b>___________</font>
                    </font>
                  </font>
                </p>
                <p className="western n mb-2" align="center"
                >
                  <font face="Times New Roman, serif">
                    <font size="10" ><b className="text-lg">PERFORMANCE
                      IN SUBJECTS</b></font>
                  </font>
                </p>
                <p className="western" >

                  <table dir="ltr" width="100%" cellpadding="7" cellspacing="0">
                    <col width="163" />
                    <col width="28" />
                    <col width="27" />
                    <col width="28" />
                    <col width="48" />
                    <col width="49" />
                    <col width="48" />
                    <col width="44" />
                    <col width="44" />
                    <col width="44" />
                    <col width="49" />
                    <col width="62" />
                    <thead>
                      <tr>
                        {/* <td width="163" height="18" valign="bottom"
                        >
                          <p className="western" align="center" style={{ orphans: 2, widows: 2 }}>
                            <font face="Times New Roman, serif">
                              <font size="2" ><span lang="en-US"><b>SUBJECTS</b></span></font>
                            </font>
                          </p>
                        </td> */}
                        {columns.map((head, id) => (
                          <td width="28" className="ass p-2" key={id}
                          >
                            <font face="Times New Roman, serif">
                              <font size="2" ><span lang="en-US"><b>{head}</b></span></font>
                            </font>

                          </td>
                        ))}

                      </tr>
                    </thead>
                    <tbody>
                      {data.map((subject, rowIndex) => (

                        <tr key={rowIndex}>
                          <td width="163" height="4"
                          >
                            <p className="western" align="left" >
                              <font face="Times New Roman, serif">
                                <font size="2" ><span lang="en-US">{subject.subjectName}</span></font>
                              </font>
                            </p>
                          </td>
                          <td width="28">
                            <p className="western" align="center" >
                              {subject.ASS}
                            </p>
                          </td>
                          <td width="27"
                          >
                            <p className="western" align="center" >
                              {subject.CA1}
                            </p>
                          </td>
                          <td width="28"
                          >
                            <p className="western" align="center" >
                              {subject.CA2}
                            </p>
                          </td>
                          <td width="48"
                          >
                            <p className="western" align="center" >
                              {subject.total_CA}
                            </p>
                          </td>
                          <td width="49"
                          >
                            <p className="western" align="center" >
                              {subject.exams}
                            </p>
                          </td>
                          <td width="48"
                          >
                            <p className="western" align="center" >
                              {subject.total}
                            </p>
                          </td>
                          <td width="44"
                          >
                            <p className="western" align="center" >
                              {parseFloat(subject.average).toPrecision(3)}

                            </p>
                          </td>
                          <td width="44"
                          >
                            <p className="western" align="center" >
                              {subject.classMax}

                            </p>
                          </td>
                          <td width="44"
                          >
                            <p className="western" align="center" >
                              {subject.classMin}

                            </p>
                          </td>
                          <td width="49"
                          >
                            <p className="western" align="center" >
                              {subject.grade}

                            </p>
                          </td>
                          <td width="62"
                          >
                            <p className="western" align="center" >
                              {subject.remark}

                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                      <tr>
                       <td colSpan={12} style={{borderBottom: "1.5pt solid #000000"}}></td> 
                      </tr>
                    
                  </table>
                  <br />

                </p>

                <p className="western" align="center" >
                  <font face="Times New Roman, serif">
                    <font size="2" >
                      <b>NO.
                        SUBJECT OFFERED: ___{data.length}_______ TOTAL SCORE: _____{result.totalScore}_______ AVERAGE
                        SCORE: ____{parseFloat(result.AverageScore).toPrecision(4)}______</b>
                    </font>
                  </font>
                </p>
                <p className="western" align="center" style={{ lineHeight: "200%", marginBottom: "0cm" }}>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i><b>GRADE:</b></i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i>
                    </i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i><b>A</b></i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i>=Excellent,
                    </i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i><b>B
                    </b></i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i>=
                      Very Good,</i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i><b>
                      C</b></i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i>
                      = Good,</i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i><b>
                      D</b></i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i>
                      = Average, </i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i><b>E</b></i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i>
                      = Fair,</i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i><b>
                      F</b></i></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><i>
                      = Fail</i></font>
                  </font>
                </p>
                <table width="100%" cellpadding="7" cellspacing="0">
                  <col width="199" />
                  <col width="61" />
                  <col width="77" />
                  <col width="249" />
                  <col width="58" />
                  <col width="64" />
                  <tr valign="top">
                    <td width="199" height="5"
                    >
                      <p class="western" align="left" >
                        <font face="Times New Roman, serif">
                          <font size="2" >
                            <span lang="en-US">EFFECTIVE
                              ASSESSMENT</span></font>
                        </font>
                      </p>
                    </td>
                    <td colspan="2" width="153"
                    >
                      <p class="western" align="center" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">RATING</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="249"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">PSYCHOMOTOR
                            ASSESSMENT</span></font>
                        </font>
                      </p>
                    </td>
                    <td colspan="2" width="136"
                    >
                      <p class="western" align="center" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">RATING</span></font>
                        </font>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td width="199" height="6"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">Attentiveness</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="61"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="77"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="249"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">Assignment</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="58"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="64"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td width="199" height="8"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">Conduct</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="61"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="77"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="249"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">Construction</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="58"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="64"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td width="199" height="8"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">Neatness</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="61"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="77"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="249"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">Fluency</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="58"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="64"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td width="199" height="8"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">Politeness</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="61"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="77"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="249"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">Hand
                            Writing</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="58"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="64"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td width="199" height="6"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">Punctuality</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="61"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="77"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="249"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">Sport
                            and Games</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="58"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="64"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td width="199" height="5"
                    >
                      <p class="western" align="justify" >
                        <font face="Times New Roman, serif">
                          <font size="2" ><span lang="en-US">Relationship</span></font>
                        </font>
                      </p>
                    </td>
                    <td width="61"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="77"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="249"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="58"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                    <td width="64"
                    >
                      <p class="western" align="justify" >
                        <br />

                      </p>
                    </td>
                  </tr>
                </table>
                <p class="western" ><br />

                </p>
                <p class="western" >
                  <font face="Times New Roman, serif">
                    <font size="2" ><b>Class
                      Teacher’s Remark:</b></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" >
                      ________________________________________________________</font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" >___________________________</font>
                  </font>
                </p>
                <p class="western" >
                  <font face="Times New Roman, serif">
                    <font size="2" ><b>Class
                      Teacher’s Name:</b></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" >
                      ________________________________________ </font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><b>Signature:</b></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" >
                      _____________________________</font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" >__</font>
                  </font>
                </p>
                <p class="western" >
                  <font face="Times New Roman, serif">
                    <font size="2" ><b>Head
                      Teacher’s Remark:</b></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" >
                      _________________________________________________________</font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" >__________________________</font>
                  </font>
                </p>
                <p class="western" >
                  <font face="Times New Roman, serif">
                    <font size="2" ><b>Head
                      Teacher:</b></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" >
                      ______________________________________________________ </font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" ><b>Signature:</b></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" >
                      ________________________</font>
                  </font>
                </p>
                <p class="western" align="center" ><a name="_GoBack"></a>
                  <font face="Times New Roman, serif">
                    <font size="2" ><b>Next
                      term Begins on:</b></font>
                  </font>
                  <font face="Times New Roman, serif">
                    <font size="2" >
                      __________________________________</font>
                  </font>
                </p>
              </div>
            </div>
          </div>
          
        }
      </>
        : <>
          <div className="h-screen flex flex-col justify-center items-center">
            <h4 className="text-xl info-text mb-5">
              No Result
            </h4>
            <Link className="bg-blue-600 rounded px-4 py-2 w-max" to={'/dashboard/' + user.role}>Back to Dashboard</Link>
          </div>
        </>}
    </div>
  );
}

export default Result;

// doctrine/dbal fideloper/proxy infobip/infobip-api-php-client intervention/image khaled.alshamaa/ar-php lcobucci/jwt
