import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // auth state
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("jcms.com.ng/user")) || null
  );
  const [token, setToken] = useState(localStorage.getItem("jcms.com.ng") || "");
  // const {tk, setToken} = u
  const navigate = useNavigate();

  const loginAction = async (data) => {
    if (data) {
      setUser(data.user);
      localStorage.setItem("jcms.com.ng/user", JSON.stringify(data.user));
      setToken(data.token);
      localStorage.setItem("jcms.com.ng", data.token);
      window.location.reload();
      navigate(`/dashboard/`);
    return true;
    }else{
      return false
    }

    
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("jcms.com.ng");
    navigate("/login");
  };

  const Authenticated = () => {
    return localStorage.getItem("jcms.com.ng") ? true : false;
  };
  //   const token = localStorage.getItem("jcms.com.ng") || false;
  const getUser = () => {
    // const {decodedToken} = useJwt(token)
    // return decodedToken;
  };

  const sessionUser = getUser();

  const isAuthenticated = Authenticated();

  return (
    <AuthContext.Provider
      value={{ token, sessionUser, user, loginAction, logOut, isAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
