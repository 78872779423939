

import { useAuth } from "../../hooks/AuthProvider";
import "./Sidebar.scss";

import AdminSideBar from "./adminSideBar";
import TeacherSideBar from './teacherSideBar'
import UserSideBar from "./userSidebar";

const Sidebar = () => {

  const {user} = useAuth()
  
  if(user.role == 'admin')
    return (
      <AdminSideBar  />
    );
  else if(user.role == 'teacher')
    return (
      <TeacherSideBar  />
    );
  else if(user.role == 'parent' || user.role == 'student')
  {
    return(<UserSideBar  />)
  }
};

export default Sidebar;
