import React, { useEffect, useState } from "react";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaGenderless,
  FaSubway,
  FaPagelines,
  FaPage4,
  FaHandPaper,
  FaPen,
} from "react-icons/fa";
import {
  Fa42Group,
  FaBook,
  FaCertificate,
  FaChalkboard,
  FaChalkboardUser,
  FaLocationPin,
} from "react-icons/fa6";
import Loader from "../../components/Loader";
import config from "../../constants/config";
import Swal from "sweetalert2";
import { useAuth } from "../../hooks/AuthProvider";
import { teachers as T } from "../../data/teacher";

const AddSubjectModal = ({ isOpen, onClose, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sections, setClasses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const { user } = useAuth();
  const [state, setState] = useState({
    name: "",
    classId: null,
    teacherId: null,
  });
  const handler = (e) => {
    e.preventDefault();

    setIsLoading(true);

    fetch(`${config.API_URL}/subject/admin`, {
      headers: config.Json,
      body: JSON.stringify(state),
      method: "POST",
    })
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Record saved",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
        onClose();
        onUpdate(result.data);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          title: "baaa!",
          text: "Something went wrong, Process failed!",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        onClose();
      });
  };
  useEffect(() => {
   
      fetch(`${config.API_URL}/classes`, { headers: config.Json })
        .then((j) => j.json())
        .then((data) => {
          setClasses(data.data);
        });
      (async () => {
        const t = await T.data();
        setTeachers(t);
      })();
    
  }, []);
  return (
    <div
      className={`fixed inset-0 flex z-40 items-center justify-center ${
        isOpen ? "bg-black bg-opacity-50" : "hidden"
      }`}
      style={{
        backgroundColor: "rgb(0, 0,0, 0.5)",
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className={`bg-white rounded-lg p-6 overflow-auto max-h-full min-h-fit w-full max-w-md mx-auto transform transition-transform duration-300 ${
            isOpen ? "scale-100 opacity-100" : "scale-90 opacity-0"
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold" style={{ color: "#383854" }}>
              Add Subjects
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              &times;
            </button>
          </div>
          <form className="space-y-4" onSubmit={handler}>
            {/* <div className="flex" style={{color:"rgb(251 146 60) !important;"}}><h4 className="mr-1 info-text text-orange-400">Warning: </h4>
          <i> No need to add a subject that is not for your class</i></div> */}

            <div className="flex items-center border-b border-gray-300 py-2">
              <FaPen className="mr-3 text-gray-400" />
              <input
                name="Name"
                placeholder="Subject Title"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                value={state.name}
                onChange={(e) => setState({ ...state, name: e.target.value })}
                required
              />
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaChalkboard className="mr-3 text-gray-400" />
              <select
                type="text"
                placeholder="Username"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="section"
                value={state.classId || ''}
                onChange={(e) =>
                  setState({ ...state, classId: e.target.value })
                }
                required
              >
                <option>Assign Class</option>
                {sections?.map((section, index) => {
                  return (
                    <option key={index} value={section.id}>
                      {section.name} ({section.number} {section.sub} )
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex items-center border-b border-gray-300 py-2">
              <FaChalkboardUser className="mr-3 text-gray-400" />
              <select
                type="text"
                placeholder="Username"
                className="w-full py-1 px-2 text-gray-700 focus:outline-none"
                name="section"
                value={state.teacherId|| ''}
                onChange={(e) =>
                  setState({ ...state, teacherId: e.target.value })
                }
                required
              >
                <option>Assign Teacher</option>
                {teachers?.map((teacher, index) => {
                  return (
                    <option key={index} value={teacher.id}>
                      {teacher.name} ({teacher.class})
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex justify-between mt-4">
              <button
                type="button"
                className="px-4 py-2 bg-gray-600 text-white rounded mr-2"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white rounded"
                style={{ backgroundColor: "#383854" }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddSubjectModal;
