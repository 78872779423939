import { useEffect, useState } from "react";

import { useAuth } from "../../hooks/AuthProvider";

import DataLoader from "../../components/DataLoader";

import "../../components/dashboard/areaCharts/AreaCharts.scss";
import "./styles.scss";
import { AreaTop } from "../../components";
import { Link, Navigate, NavigationType } from "react-router-dom";
import config from "../../constants/config";
import TermModal from "./termModal";
import { Result } from "../../screens";
import TermComponent from "../../screens/results/termComponent";

const ResultPage = () => {
  const { token, user } = useAuth();
  const [canUpadte, setCanUpdate] = useState(true);
  const [children, setChildren] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [ready, setReady] = useState(false);
  const [form, setForm] = useState(null);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    if (canUpadte) {
      (async () => {
        setIsBusy(true);
        user.role == "parent"
          ? fetch(`${config.API_URL}/parent/student/${user.parent.id}`, {
            headers: { token: config.token },
          })
            .then((json) => json.json())
            .then((res) => {
              if (res.code == 1) {
                setChildren(res.data);
              }
              setIsBusy(false);
              setCanUpdate(false);
            })
          : (() => {
            fetch(`${config.API_URL}/sessions`, { headers: config.Json })
              .then((json) => json.json())
              .then((res) => {
                if (res.code == 1) {
                  setSessions(res.data);
                  setIsBusy(false);
                  setCanUpdate(false);
                }
              });
          })();
      })();
    }
  }, []);

  const showModal = (id) => {
    setId(id);
    setModalOpen(true);
  };

  const onSet = () => {
    const ses = sessionStorage.getItem("terms");
    setModalOpen(false);
    if (ses) {
      const sesData = JSON.parse(ses);
      setForm({ ...sesData, studentId: id });
      setReady(true);
    }
  };

  const ResultComponent = () => (
    <Result id={id} data={form} close={() => setReady(false)} />
  );

  const ParentComponent = () => {
    return (
      <>
        <h4 className="info-text">
          {children?.length} {children?.length > 1 ? " Children " : " Child "}
          found
        </h4>
        <div className="content-area-cards">
          {children?.map((child, index) => {
            return (
              <div className="area-card" key={index}>
                <div className="area-card-info">
                  <div className="info-value capitalize">{child.firstName}</div>
                  <h5 className="info-title capitalize">{`${child.section.name} ${child.section.number} (${child.section.sub})`}</h5>
                </div>
                <div className="area-chart-cards">
                  <button
                    onClick={() => showModal(child.id)}
                    className="bg-blue-600 py-2 px-4 rounded "
                  >
                    Check Result
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const StudentComponent = () => {
    return (
      <div className="content-area-cards">
        {sessions?.map((session, index) => {
          return (
            <div key={index}>
              <h2 className="text-lg text-green-950 info-text font-bold m-2">
                {session.name} - Session
              </h2>

              <>
                {session.terms?.map((term, i) => {
                  return (
                    <div className="area-card" key={i}>
                      <div className="area-card-info">
                        <div className="info-value capitalize">{term.name}</div>
                        <h5 className="info-title capitalize">{` (${session.status == "active" ? "current" : "previous"
                          })`}</h5>
                      </div>
                      <div className="area-chart-cards">
                        <Link
                          to={`result/${user?.student?.id}`}
                          className="bg-blue-600 py-2 px-4 rounded "
                        >
                          Check Result
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </>
            </div>
          );
        })}
      </div>
    );
  };

  if (user.role && user.role == "admin") {
    return (
      <>
        {isBusy ? (
          <DataLoader text={"loading..."} />
        ) : (
          <>
            <TermComponent sessions={sessions} />
          </>
        )}
      </>
    );
  } else if (user.role && user.role == "cashier") {
    return <Navigate to={"/dashboard"} />;
  } else
    return (
      <>
        {isBusy ? (
          <DataLoader text={"loading..."} />
        ) : (
          <>
            {ready ? (
              <ResultComponent />
            ) : (
              <div className="content-area">
                <AreaTop title={"Results"} />

                {user.role == "parent" ? (
                  <ParentComponent />
                ) : (
                  <StudentComponent />
                )}
                {/* <Performance /> */}
                <TermModal
                  isOpen={modalOpen}
                  id={id}
                  onClose={() => {
                    setModalOpen(false);
                    setId(null);
                  }}
                  onSet={onSet}
                />
              </div>
            )}
          </>
        )}
      </>
    );
};

export default ResultPage;
