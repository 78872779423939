import React, { useEffect, useState } from "react";
import { AreaTop } from "../../components";

import DataLoader from "../../components/DataLoader";
import config from "../../constants/config";

import "./style.scss";
import { useAuth } from "../../hooks/AuthProvider";
import { Link, Navigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import LoadingButton from "../../components/LoadingButton";
import CustomAlert from "../../components/CustomeAlert";
import Swal from "sweetalert2";
const columns = [
  "Name",
  "Ass (10)",
  "CA 1 (10)",
  "CA 2 (20)",
  "Total CA (40)",
  "Exams (60)",
  "Total (100)",
  "Average",
  "Grade",
  "Remark",
];

function Spreedsheet() {
  const [data, setData] = useState([]);
  const [editData, setEditedData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [update, setUpdate] = useState(true);
  const [isBusy, setIsBusy] = useState(true);
  const [text, setText] = useState("Fetching students record");
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [section, setSection] = useState({})

  const { user } = useAuth();
  const { subjectId, subjectName, teacherId } = useParams();

  const handleChange = (e, rowIndex, cols) => {
    if (e.target.validity.valid) {
      const newData = [...data];
      const edited = [...editData];
      newData[rowIndex].assesment[cols] = parseFloat(e.target.value);
      setData(newData);
      edited[rowIndex] = newData[rowIndex];
      setEditedData(edited);
      updateGrades();
    } else {
      e.target.reportValidity();
    }
    // console.log(editData)
  };

  const conditionalRedirect = () => {};

  useEffect(() => {
    conditionalRedirect();
    if (update) {
      setIsBusy(true);
      (async () => {
        const resp = await fetch(
          `${config.API_URL}/assesments/subject/${subjectId}/class/${
            teacherId ? teacherId : user.teacher?.currentClass
          }`,
          { headers: config.Json }
        );
        const data = await resp.json();
        setData(data.assesments);
        setInitialData(data.assesments);
        setSection(data.section)
        setIsBusy(false);
      })();

      setUpdate(false);
      updateGrades();
      setIsBusy(false);
    }
  });

  const showAlert = () => {
    setAlertOpen(true);
    setTimeout(() => setAlertOpen(false), 3000);
  };

  const handleSaveAll = async () => {
    const newData = [];
    setText("Uploading Please relax it's going to take a while...");
    setLoading(true);
    setIsBusy(true);
    let done = 0;
    let exp = editData ? editData.length : data.length;
    if (editData) {
      exp = editData.length;

      editData.forEach(async (student, index) => {
        const ass = student.assesment;
        const result = {
          subjectId: parseFloat(subjectId),
          studentId: student.id,
          classId: student.currentClass,
          ...ass,
        };
        fetch(`${config.API_URL}/assesment/${subjectId}`, {
          method: "POST",
          body: JSON.stringify(result),
          headers: config.Json,
        })
          .then((r) => r.json())
          .then((res) => {
            const newStudents = [...data];
            newStudents[index].assesment = res;
            setData(newStudents);
            done += 1;
            updateGrades();
            Swal.fire({
              title: "Done!",
              text: "Your Work Saved",
              timer: 2000,
              timerProgressBar: true,
              icon: "success",
            });
          })
          .catch((err) => {
            // setAlertText(`Error  uploading... ${student.lastName} ${student.lastName} records`);
            Swal.fire({
              title: "Error!",
              text: "Sorry.! Something went wrong",
              timer: 5000,
              timerProgressBar: true,
              icon: "error",
            });
            done += 1;
            setLoading(false);
          })
          .finally((e) => {
            setLoading(false);
          });

        if (done == exp) {
          updateGrades();
          Swal.fire({
            title: "Done!",
            text: "Your Work Saved",
            timer: 5000,
            timerProgressBar: true,
            icon: "success",
          });
          // (false);
          setIsBusy(false);
          setLoading(false);
        }
      });
      setEditedData([]);
    } else {
      exp = data.length;
      data.forEach(async (student, index) => {
        const ass = student.assesment;
        const result = {
          subjectId: parseFloat(subjectId),
          studentId: student.id,
          classId: student.currentClass,
          ...ass,
        };
        fetch(`${config.API_URL}/assesment/${subjectId}`, {
          method: "POST",
          body: JSON.stringify(result),
          headers: config.Json,
        })
          .then((r) => r.json())
          .then((res) => {
            const newStudents = [...data];
            newStudents[index].assesment = res;
            setData(newStudents);
            updateGrades();
            done += 1;
            Swal.fire({
              title: "Done!",
              text: "Your Work Saved",
              timer: 2000,
              timerProgressBar: true,
              icon: "success",
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Error!",
              text: "Sorry.! Something went wrong",
              timer: 5000,
              timerProgressBar: true,
              icon: "error",
            });
            done += 1;
            setLoading(false);
          });
      });
      if (done == epx) {
        updateGrades();
        Swal.fire({
          title: "Done!",
          text: "Your Work Saved",
          timer: 5000,
          timerProgressBar: true,
          icon: "success",
        });
        isBusy(false);
      }
    }

    if (done == exp) {
      updateGrades();
      Swal.fire({
        title: "Done!",
        text: "Your Work Saved",
        timer: 5000,
        timerProgressBar: true,
        icon: "success",
      });
      // (false);
      setIsBusy(false);
      setLoading(false);
    }
  };

  const handleReset = () => {
    if (confirm("Are you sure you want to reset this sheet.?"))
      setData(initialData);
  };

  const updateGrades = () => {
    setData(
      data.map((student, id) => {
        student.assesment.total =
          parseFloat(student.assesment?.ASS || 0) +
          parseFloat(student.assesment?.CA1 || 0) +
          parseFloat(student.assesment?.CA2 || 0) +
          parseFloat(student.assesment?.exams || 0);
        student.assesment.total_CA =
          parseFloat(student.assesment?.ASS || 0) +
          parseFloat(student.assesment?.CA1 || 0) +
          parseFloat(student.assesment?.CA2 || 0);
        // grades
        let { grade, remark } = grader(student.assesment?.total || 0);
        student.assesment.grade = grade;
        student.assesment.remark = remark;

        return {
          ...student,
        };
      })
    );
  };
  const grader = (total) => {
    let grade = "F";
    let remark = "Fair";
    total = total || 0;
    if (total < 40) {
      grade = "F";
      remark = "Fair";
    }

    if (total >= 40 && total < 50) {
      grade = "D";
      remark = "Pass";
    }

    if (total >= 50 && total < 60) {
      grade = "C";
      remark = "Good";
    }

    if (total >= 60 && total < 70) {
      grade = "B";
      remark = "V. Good";
    }

    if (total >= 70 && total <= 100) {
      grade = "A";
      remark = "Excellent";
    }
    return { grade, remark };
  };

  if (user.role != "teacher" && !teacherId) {
    return <Navigate to={"/dashboard"} replace />;
  } else
    return (
      <div className="context-area">
        <AreaTop />
        {loading ? (
          <DataLoader text={text} />
        ) : (
          <div className="container mx-auto">
            <div className="flex justify-between mb-4">
              <button
                onClick={handleSaveAll}
                className="bg--500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                style={{
                  backgroundColor: "rgba(29, 78,216,  1",
                  color: "#fff",
                }}
              >
                Publish and Save
              </button>

              <button
                onClick={(e)=>window.history.back()}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Back
              </button>
            </div>

            <div className="flex items-center justify-center h-max ">
              {/* <LoadingButton onClick={handleButtonClick} isLoading={loading}>
              Click Me
            </LoadingButton>

            <button
              className="px-4 py-2 font-semibold text-white bg-green-500 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              onClick={showAlert}
            > */}
              {/* Show Alert
            </button> */}

              <CustomAlert
                message={alertText}
                type="success"
                isOpen={alertOpen}
                onClose={() => setAlertOpen(false)}
              />
            </div>

            <div className="overflow-auto content-area-table">
              <table className="min-w-full  border border-gray-700">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border text--600" colSpan={8}>
                      Continues Assesment Sheet for {subjectName}
                    </th>
                    <th className="px-4 py-2 border text--600" colSpan={2}>
                      Class: {section?.name} {section?.number}({section?.sub})
                    </th>
                  </tr>
                  <tr>
                    <th className="px-4 py-2 border text--600"></th>
                    <th className="px-4 py-2 border text--600" colSpan={7}>
                      Continues Assesment
                    </th>
                    <th className="px-4 py-2 border text--600" colSpan={2}>
                      Comment
                    </th>
                  </tr>
                  <tr>
                    <th className="px-4 py-2 border text--600"></th>
                    <th className="px-4 py-2 border text--600">Assignment</th>
                    <th className="px-4 py-2 border text--600" colSpan={2}>
                      Tests
                    </th>
                    <th className="px-4 py-2 border text--600">Total CA</th>
                    <th className="px-4 py-2 border text--600">Exams</th>
                    <th className="px-4 py-2 border text--600" colSpan={2}>
                      TOTAL
                    </th>
                    <th className="px-4 py-2 border text--600" colSpan={2}>
                      Comment
                    </th>
                  </tr>
                  <tr className="text--600">
                    {columns.map((value, index) => {
                      return (
                        <th key={index} className="px-4 py-2 border text--600">
                          {value}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((student, rowIndex) => (
                    <tr key={rowIndex} className="text-gray-600 font-bold">
                      <td className="px-4 py-2 border text--600 uppercase">
                        {student.firstName} {student.lastName}
                      </td>
                      <td className="px-1 py-2 border w-1/12">
                        <input
                          type="number"
                          className="w-full h-full p-2 border-hidden bg-inherit text--800 text-right "
                          value={
                            student.assesment?.ASS ? student.assesment?.ASS : 0
                          }
                          onChange={(e) => handleChange(e, rowIndex, "ASS")}
                          max={10}
                          min={0}
                          step={0.5}
                        />
                      </td>
                      <td className="px-1 py-2 border w-1/12">
                        <input
                          type="number"
                          className="w-full h-full p-2 border-hidden bg-inherit text--800 text-right "
                          value={
                            student.assesment?.CA1 ? student.assesment?.CA1 : 0
                          }
                          onChange={(e) => handleChange(e, rowIndex, "CA1")}
                          min={0}
                          max={10}
                          step={0.5}
                        />
                      </td>
                      <td className="px-1 py-2 border w-1/12">
                        <input
                          type="number"
                          className="w-full h-full p-2 border-hidden bg-inherit text--800 text-right "
                          value={
                            student.assesment?.CA2 ? student.assesment?.CA2 : 0
                          }
                          onChange={(e) => handleChange(e, rowIndex, "CA2")}
                          max={20}
                          min={0}
                          step={0.5}
                        />
                      </td>
                      <td className="px-1 py-2 border w-1/12">
                        <input
                          type="number"
                          className="w-full h-full p-2 border-hidden bg-inherit text--800 text-right "
                          value={student.assesment?.total_CA || 0}
                          onChange={(e) =>
                            handleChange(e, rowIndex, "total_CA")
                          }
                          disabled
                        />
                      </td>
                      <td className="px-1 py-2 border w-1/12">
                        <input
                          type="number"
                          className="w-full h-full p-2 border-hidden bg-inherit text--800 text-right "
                          value={
                            student.assesment?.exams
                              ? student.assesment?.exams
                              : 0
                          }
                          onChange={(e) => handleChange(e, rowIndex, "exams")}
                          max={60}
                          min={0}
                          step={0.5}
                        />
                      </td>
                      <td className="px-1 py-2 border w-1/12">
                        <input
                          type="number"
                          className="w-full h-full p-2 border-hidden bg-inherit text--800 text-right "
                          value={student.assesment.total || 0}
                          onChange={(e) => handleChange(e, rowIndex, "total")}
                          disabled
                        />
                      </td>
                      <td className="px-1 py-2 border w-1/12">
                        <input
                          type="number"
                          className="w-full h-full p-2 border-hidden bg-inherit text--800 text-right "
                          value={student.assesment.total / 4 || 0}
                          onChange={(e) => handleChange(e, rowIndex, "total")}
                          disabled
                        />
                      </td>
                      <td className="px-1 py-2 border w-1/12">
                        <input
                          type="text"
                          className="w-full h-full p-2 border-hidden bg-inherit text--800 text-left "
                          value={student.assesment.grade || "F"}
                          onChange={(e) => handleChange(e, rowIndex, "grade")}
                          disabled
                        />
                      </td>
                      <td className="px-1 py-2 border w-1/12">
                        <input
                          type="text"
                          className="w-full h-full p-2 border-hidden bg-inherit text--800 text-left "
                          value={student.assesment.remark || "Fair"}
                          onChange={(e) => handleChange(e, rowIndex, "remark")}
                          disabled
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex flex-row-reverse justify-between mt-4">
              <button
                onClick={handleSaveAll}
                className="bg--500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                style={{
                  backgroundColor: "rgba(29, 78,216,  1",
                  color: "#fff",
                }}
              >
                Publish and Save
              </button>

              <button
                onClick={(e)=>window.history.back()}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Back
              </button>
            </div>
            </div>
            {loading ? <Loader /> : <></>}
          </div>
        )}
      </div>
    );
}

export default Spreedsheet;
