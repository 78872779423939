import { useState } from "react";
import {
  AreaCards,
  AreaCharts,
  AreaTable,
  AreaTop,
  StudentResults,
} from "../../components";
import { useAuth } from "../../hooks/AuthProvider";
import { Navigate } from "react-router-dom";
import { DashboardTeacher, ParentDashboard } from "../../site";

const Dashboard = () => {
  const { user } = useAuth();

  if (user.role == "teacher") {
    return <DashboardTeacher />;
  }
  else if (user.role == "parent") {
    return <ParentDashboard />;
  }
  else if (user.role == "student") {
    return <ParentDashboard />;
  }
  else if (user.role == "admin") {
    return (
      <div className="content-area">
        <AreaTop />
        <AreaCards />
        <AreaCharts />
        {/* <AreaTable /> */}
      </div>
    );
  }else{
    return <Navigate to={'/login'} replace />
  }
};

export default Dashboard;
