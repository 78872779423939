import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import { ThemeProvider } from "./context/ThemeContext.jsx";
import { SidebarProvider } from "./context/SidebarContext.jsx";
import { PrimeReactProvider } from "primereact/api";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Portal = () =>
    <ThemeProvider>
        <SidebarProvider>
            <PrimeReactProvider>
                <App />
            </PrimeReactProvider>
        </SidebarProvider>
    </ThemeProvider>;

export default Portal;
